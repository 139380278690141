/* ==========================================================================
   # Realestate Feature
   ========================================================================== */

// Realestate Feature Base Styles
//
.realestate-features {
  position: relative;
  height: 100%;
  // padding-top: 46px;
  padding-bottom: 46px;

  .realestate-feature-column {
    display: block;
    @include make-xs-column(12);
    @include make-md-column(6);
    @include make-lg-column(4);

    @media (min-width: $screen-md-min) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .realestate-feature {
      border: 1px solid $brand-seperator-default;
      margin-top: -2px;

      .realestate-feature-heading {
        background-color: $brand-white;
        border-bottom: 1px solid $brand-seperator-default;
        color: $brand-red;
        padding: 8px 15px;
      }
      .realestate-feature-collapse {
        overflow: hidden;

        .realestate-feature-body {
          padding: 15px 0;

          ul {
            list-style: none;
            margin-top: 12px;
            margin-bottom: 12px;
            padding-left: 15px;

            li {
              line-height: 1.8;
              margin-left: -4px;

              &:before {
                position: relative;
                display: inline-block;
                content: "";
                top: -3px;
                margin-right: 4px;
                width: 4px;
                height: 4px;
                @include vendor(border-radius, 100px);
                background-color: $brand-blue;
              }
            }
          }
        }
      }
    }
  }
}

.ul-one-column {
  @include vendor(columns, 1);
}

.ul-two-columns {
  @include vendor(columns, 2);

  li:nth-child(odd) {
    // margin-right: 0;
    // background: red;
  }
  li:nth-child(even) {
    // margin-right: 0;
    // background: blue;
  }
}

@media (min-width: $screen-md-min) {

  // Disable clicking on desktop
  //
  .realestate-feature-heading {

    a.collapsed {
      pointer-events: none;
      cursor: default;
    }
  }

  .realestate-feature-collapse {
    height: auto !important;
    min-height: 260px;

    &.collapse {
      display: block;
    }
  }
}

// Downloads
//
.downloads {
  display: table;
  @include make-row();
  padding-top: 46px;
  padding-bottom: 46px;

  @media (min-width: $screen-md-min) {
    padding-top: 0px;
    padding-bottom: 61px;
  }

  @media (max-width: $screen-xs-max) {
    display: block;
    text-align: center;
    // padding-top: 0px;
  }

  .downloads__inner {
    display: table-cell;
    vertical-align: middle;

    @media (max-width: $screen-xs-max) {
      display: block;
      margin: 0 auto;
      text-align: center;
    }

    .downloads-title {
      display: inline-block;
      margin-right: 15px;

      @media (max-width: $screen-xs-max) {
        margin-right: 0;
        margin-bottom: 15px;
        display: block;
      }
    }
    .btn-group {
      margin-right: 15px;
      @media (max-width: $screen-xs-max) {
        margin-right: 0;
      }
    }
  }
}
