/* ==========================================================================
   # Components Gallery Pagination
   ========================================================================== */

// Base Styles
//
.immo-pagination {
  z-index: -1;
  height: 16px;

  &.swiper-pagination-bullets {
    // bottom: 86px;
  }
  > .swiper-pagination-bullet {

    @media (min-width: $screen-md-min) {
      margin: 0 6px;
      width: 16px;
      height: 16px;
    }

    &.swiper-pagination-bullet-active {
      background-color: $brand-red;
    }
  }
}
