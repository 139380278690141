/*Thematic Breaks
------------------------------------*/
hr {
	margin: 30px 0;
	border-top: 1px solid $brand-seperator-default;
}

hr.hr-xs {
	margin: 10px 0;
}

hr.hr-md {
	margin: 20px 0;
}

hr.hr-lg {
	margin: 40px 0;
}
