/* ==========================================================================
   # Login / Register
   ========================================================================== */

// Login / Register Overlay
//
#loginRegisterTab {
  background: $brand-white;
  display: none;

  &.open {
    display: block;
  }
}

.login-register {
  @extend .container;
  background: $brand-blue;

  @media (max-width: $screen-sm-max) {
    width: 100%;
    // margin-left: 0;
    // margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .login-register__inner {
    position: relative;
    display: table;
    width: 100%;

    .login-register-wrapper {
      position: absolute;
      display: table-cell;
      width: 100%;

      @media (min-width: $screen-md-min) {
        bottom: 0px;
      }

      .title {
        .title__heading {
          margin-bottom: 20px;
        }
      }

      // Different Contents
      //
      .login-contents,
      .register-contents,
      .forget-password-contents {
        @include make-xs-column(12);
        @include make-md-column(4);
        @extend .pull-right;
        padding: 36px;
        background: $brand-red;
        color: $brand-white;
        z-index: $zindex-popover;
        @include box-shadow(0 6px 12px rgba(0,0,0,.175));

        .form-inline {
          display: table;
          vertical-align: middle;
          width: 100%;

          .forget-password {
            display: table-cell;
            vertical-align: middle;
          }
          .login-button {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .fade {
    opacity: 0;
    @include transition(opacity 0s linear);
    &.in {
      opacity: 1;
    }
  }
}

.topbar-item--login-register a {
  color: $brand-blue;
  a:hover {
    cursor: pointer;
  }
}
