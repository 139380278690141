.realestate-units {
  @include make-xs-column(12);
  padding-bottom: 46px;
}

@media (max-width: $screen-sm-max) {

	.realestate-units-table table,
	.realestate-units-table thead,
	.realestate-units-table tbody,
	.realestate-units-table th,
	.realestate-units-table td,
	.realestate-units-table tr {
		display: block;
	}

	.realestate-units-table thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

  .realestate-units-table tbody tr {
    margin-bottom: 15px;
  }

	.realestate-units-table tr { border: 1px solid #ccc; }

	.realestate-units-table td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		white-space: normal;
		text-align:left;
	}

	.realestate-units-table td:before {
    position: relative;
    display: block;
    padding-right: 10px;
    white-space: nowrap;
    text-align:left;
    font-weight: bold;
  }
	.realestate-units-table td:before { content: attr(data-title); }
}

.realestate-units-table {
  .table-bordered {
    width: 100%;
    border: 1px solid $brand-seperator-default;

    @media (max-width: $screen-sm-max) {
      border: 0;
    }

    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border: 0;
          border-bottom: 1px solid $brand-seperator-default;
        }
      }
    }
  }
}

.realestate-units-table table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 15px;
      }
    }
  }
}

ul.list-table {
  li {
    display: block !important;
  }
}

.realestate-units-table {

  .btn-group {
    margin-top: 24px;
    margin-bottom: 24px;

    .btn--blue {

      &:hover,
      &:active {
        background-color: $brand-secondary !important;
      }
    }
  }
}
