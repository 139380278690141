/* ==========================================================================
   # News Archive
   ========================================================================== */

// News Archive Base Styles
//

.news-container {
  @extend .container;

  .news-container__row {
    @include make-row;

    .news-wrapper {
      @include make-xs-column(12);

      .news-archive__item {
        display: block;
        margin-top: 46px;
        margin-bottom: 46px;

        @media (min-width: $screen-md-min) {
          height: 432px;
        }

        .news-archive__content {
          @include make-xs-column(12);
          @include make-md-column(6);
          position: relative;
          display: inline-block;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $brand-white;
          border: 1px solid $brand-seperator-default;
          max-height: 100%;
          padding: 15px;
          box-sizing: border-box;
          overflow: hidden;

          @media (min-width: $screen-sm-min) {
            padding: 46px;
          }

          @media (max-width: $screen-sm-max) {
            height: auto;
          }

          .news-archive-article {
            width: 100%;
            max-height: 286px;
            margin-bottom: 24px;
            overflow: hidden;

            @media (min-width: $screen-md-min) {
              max-height: 294px;
              margin-bottom: 24px;
            }

            .title, p {
              margin-bottom: 14px;
            }
          }
          .btn-group {
            .btn--bold {
              .fa {
                padding-left: 4px;
              }
            }
          }
        }
        .news-archive__image {
          @include flexbox;
          @include align-items(center);
          @include justify-content(center);

          @include make-xs-column(12);
          @include make-md-column(6);
          height: 100%;
          padding-left: 0;
          padding-right: 0;
          background-size: cover;
          background-position: center;

          @media (max-width: $screen-sm-max) {
            height: 260px;
          }

          .news-archive__date {
            position: absolute;
            bottom: 15px;
            left: 15px;
            color: $brand-white;
            font-weight: $bold-font-weight;
            @media (min-width: $screen-md-min) {
              bottom: 24px;
              left: 24px;
            }
          }
          .btn--bold {
            &,
            &:active,
            &:hover {
              color: $brand-white !important;
            }
          }
        }

        &:nth-child(odd){

          .news-archive__image {
            @extend .pull-right;
          }
          .news-archive__content {
            @extend .pull-left;
          }
        }
      }
    }
  }
}

// Checkboxes as Button Hack
//
.news-archive-filter-btn {
  padding-top: 46px;

  .facetwp-checkbox {
  	position: relative;
  	display: inline-block;
    cursor: pointer;
    box-shadow: none;
    font-weight: $bold-font-weight;
    border-radius: 0;
    padding: 10px 18px !important;
    margin: 4px !important;
    color: $brand-white;
    background-color: $brand-blue !important;
    @include vendor(transition, $brand-transition);


  	&:before {
      display: none;
  	}

    &:hover,
    &:active,
  	&.checked {
      // background-color: darken($brand-blue, 8%) !important;
      background-color: $brand-secondary !important;

      &:after {
        display: none;
      }
  	}
    &:first-child {
      margin-left: 0 !important;
    }
    &:last-child {
      margin-right: 0 !important;
    }
  }

}
