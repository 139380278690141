.search-modal {
  display: none;
  transition: opacity .2s;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($brand-primary, .95);

  .search-modal__close {
    position: absolute;
    top: 60px;
    right: 80px;
    border: 0;
    background: none;
    background-image: url(../images/close-icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 22px;
    height: 22px;
    cursor: pointer;
    outline: none;
  }

  .search-block {
    position: absolute;
    width: 90%;
    max-width: 1060px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: $screen-md-min) {
    display: block;
    opacity: 1;
    pointer-events: all;

    &.closed {
      opacity: 0;
      pointer-events: none;
    }
  }
}
