/* ==========================================================================
   # Select Field
   ========================================================================== */

// Select Options Base
//

// Hide the Select attribute
//
.select-hidden {
  display: none;
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font-size: 16px;
  // padding: 10px 18px;
  color: $brand-blue;
  width: 100%;
  min-height: 44px;
  max-height: 44px;
  height: auto;
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $brand-white;
  padding: 10px 18px;

  &:after {
    content: "\f107";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    margin-top: 4px;
    float: right;
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;

    // content:"";
    // width: 0;
    // height: 0;
    // border: 7px solid transparent;
    // border-color: red transparent transparent transparent;
    // position: absolute;
    // top: 16px;
    // right: 10px;
  }

  &:hover {
    // background-color: darken($select-background, 2);
  }
  &:active, &.active {
    // background-color: darken($brand-blue, 5);

    &:after {
      content: "\f106"!important;
    }
  }
}

.select-options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: $brand-white;
  @include box-shadow(0 6px 12px rgba($gray-base, .175));

  li {
    margin: 0;
    padding: 12px 0;
    text-indent: 15px;
    border-top: 1px solid $brand-light-seperator;
    // @include transition(all 0.15s ease-in);

    &:hover {
      // color: $brand-red;
      background: $brand-grey-lighter;
    }
    &[rel="hide"] {
      display: none;
    }
  }
}
