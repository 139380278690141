/*----------------------------------------------------------------------

[-----*** TABLE OF CONTENT ***-----]

1. Header - Topbar
2. Header - Navbar
3. Header - Responsive Navbar Style
-----------------------------------------------------------------------*/

/*--------------------------------------------------
    [1. Header - Topbar]
----------------------------------------------------*/

/*Top Bar (login, search etc.)
------------------------------------*/
.topbar {
    z-index: 12;
    padding: 8px 0;
    position: relative;

    @media (max-width: $screen-sm-max) {
        margin-bottom: 26px;
    }

    .topbar-items {
        float: right;
        margin: 0;

        > li,
        .topbar-item {
            display: inline;
            list-style: none;
            position: relative;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }

            i.im:before {
                top: 3px;
            }
            i.im-phone:before {
                top: 6px;
            }

            > a {
                // color: $link-color;
                font-weight: 400;
                font-size: $font-size-small;
                font-weight: $bold-font-weight;
                transition: $brand-transition;

                &:hover {
                    color: $immo-grey;
                    text-decoration: none;
                }
            }
        }
    }
    // Hide the topbar @breakpoint 767px
    @media (max-width: $screen-xs-max) {
        &.topbar-default {
            display: none;
        }
    }
    // Alternative Telephone version
    &.topbar-mobile {
        display: none;
        background: $brand-white;

        @media (max-width: $screen-xs-max) {
            & {
                position: relative;
                height: 100%;
                width: 100%;
            display: table-cell;
            vertical-align: middle;
                padding: 0;
                margin: 0 auto;
                // margin-left: 20px !important;
            }
        }
        .topbar-items {
            display: block;
            float: none;
            text-align: center;
            padding: 0;

            .topbar-item--whatsapp {
                text-align: center;
                padding: 0;

                a {
                    text-align: center;
                    text-decoration: none;
                    color: $brand-blue;
                }
            }
        }
    }
}

// <div class="topbar topbar-mobile">
//     <ul class="topbar-items">
//         <li class="topbar-item topbar-item--whatsapp">
//             <i class="im im-whatsapp"></i>
//             <a href="#tel">+32 (0) 3 233 53 33</a>
//         </li>
//     </ul>
// </div>

/*--------------------------------------------------
    [2. Header - Navbar]
----------------------------------------------------*/

/*Navbar (main menu)
------------------------------------*/
.header {
    z-index: $zindex-navbar;
    background: $navbar-default-bg;
    position: relative;
    border-bottom: 1px solid $brand-light-seperator;

    &__inner {
        @extend .container;
        display: table;
        margin-bottom: -50px;

        @media (max-width: $screen-sm-max) {
            width: 100%;
        }
        @media (max-width: $screen-sm-max) {
            margin-bottom: 0;
        }
    }

    @media (min-width: $screen-md-min) {
        .search-block {
            float: right;

            .search-block__btn {
                position: relative;
                transform: none;
                margin-left: 40px;
                top: 5px;
                right: 0;
            }
        }
    }
}

/*Logo
------------------------------------*/
.logo {
    height: 100%;
    display: table-cell;
    vertical-align: middle;

    img {
        margin-top: 8px;
        position: relative;
        z-index: 1;
        @media (max-width: $screen-sm-max) {
            margin-bottom: -6px;
        }
    }
}

/*Navbar Toggle
------------------------------------*/
.navbar-toggle {
    margin-right: 0;
    border: 0;

    .fa {
        color: $brand-blue;
        // font-size: 36px;

        &.fa-bars {
            width: 30px;
            height: 30px;
            &:before {
                display: inline-block;
                content: url(../images/hamburger-icon-normal.svg);
                width: 30px;
                height: 30px;
            }
        }
    }

    &,
    &:focus {
        padding: 8px 10px 0px;
        background: $brand-white;
        border-radius: 0;
    }
    // &:hover {
    //     background: darken($brand-red, 4);
    // }
    &.open {
        .fa-bars {
            width: 30px;
            height: 30px;

            &:before {
                // content: "\f00d";
                display: inline-block;
                content: url(../images/hamburger-icon-close.svg);
            }
        }
    }
}


/*Navbar Collapse*/
.header .navbar-collapse {
    position: relative;

    .container {
        @media (max-width: $screen-sm-max) {
            width: 100%;

        }

        // Add the divider border
        @media (min-width: $screen-md-min) {
            &:before {
                content: "";
                display: block;
                position: relative;
                top: -12px;
                right: 0;
                margin-left:350px;
                height: 1px;
                background: $border-color;
            }
        }
    }
}

/*Navbar Collapse*/
@media (max-width: $screen-sm-max) {
    .navbar-collapse,
    .navbar-collapse .container {
        padding-left: 0;
        padding-right: 0;
    }
}

/*--------------------------------------------------
    [3. Header - Responsive Navbar Style]
----------------------------------------------------*/

/*Navbar Menu 0px and up to 768px
------------------------------------*/
@media (max-width: $screen-xs-max) {

    .header {
        border-top: 1px solid $brand-seperator-default;

        .header__inner {
            // table-layout: fixed;
        }
    }

    // .logo {
    //     width: 120px;
    // }

    // .topbar-item--whatsapp {
    //     display: block !important;
    // }

    .navbar-toggle {
        display: table-cell !important;
        margin-top: 0;
        margin-bottom: 0px;
        height: 100%;
        border-left: 1px solid $brand-seperator-default;

        .fa-bars {
            display: table-cell;
            vertical-align: middle;
        }

        &,
        &:focus {
            padding-right: 0px;
            padding-left: 15px;
            padding-top: 14px;
            padding-bottom: 14px;
            background: $brand-white;
            border-radius: 0;
        }
    }
    // Navbar Nav
    .logo {
        height: 100%;
        padding-right: 15px;
        display: table-cell;
        vertical-align: middle;
        border-right: 1px solid $brand-light-seperator;

        img {
            margin-top: 8px;
            position: relative;
            max-height: 60px;
            z-index: 1;
            @media (max-width: $screen-sm-max) {
                margin-bottom: -6px;
            }
        }
    }
}

/*Navbar Menu 0px and up to 992px
------------------------------------*/
@media (max-width: $screen-sm-max) {

    // Navbar Nav
    .navbar-nav {
        // reset float
        float: none;
        position: relative;
        background: $body-bg;
        margin: 0;

        > li {
            float: none;
            width: 100%;
            background-color: $brand-blue;
            color: $brand-white;
            text-align: center;
            border-bottom: 1px solid $brand-seperator-default;

            > a {
                padding-top: 24px;
                padding-bottom: 24px;
                color: $brand-white;
                font-size: $font-size-h4;
                font-weight: $bold-font-weight;
                text-align: center;
                text-transform: normal;
                text-decoration: none;
                @include vendor(transition, $brand-transition * 0.4);

                &:hover,
                &:focus {
                    color: $brand-red;
                }
            }
            &.active > a {
                color: $brand-red;
            }
            &.red a.red {
                color: $brand-red;
            }
        }
        li.social-icons-mobile-link {
            background-color: white;
            // height: 80px;
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }
    .navbar-toggle {
        display: block;
    }
    // override here the default
    .navbar-collapse.collapse {
        display: none !important;
    }
    .navbar-collapse.collapse.in {
        display: block !important;
        overflow-y: auto !important;
    }
}


/*Navbar Menu max 992px
------------------------------------*/
@media (min-width: $screen-md-min) and (max-width: $screen-lg) {
    .logo img {
        max-width: 200px;
        height: auto;
        margin-top: 30px;
    }
}

/*Navbar Menu 992px and up
------------------------------------*/
@media (min-width: $screen-md-min) {
    // Navbar Collapse
    .navbar-collapse {
        padding: 0;
    }
    // Navbar Nav
    .navbar-nav {
        // float navigation from medium screens
        float: right;
        position: relative;

        .login-register-mobile-link,
        .social-icons-mobile-link,
        .mobile-only-menu-item {
            display: none;
        }

        li {
            background: none;

            a {
                position: relative;
                bottom: 5px;
                padding: 9px 20px;
                color: $text-color;
                font-size: $font-size-base;
                font-weight: $bold-font-weight;
                text-transform: uppercase;
                text-decoration: none;
                @include vendor(transition, $brand-transition);

                &:hover,
                &:focus {
                    color: $link-color;
                    background: none;
                    // border-bottom: solid 2px $brand-red;
                    text-decoration: none;
                }
            }

            &.active > a {
                color: $link-color;
                // border-bottom: solid 2px $brand-red;
                text-decoration: none;
            }

            &.open {
                a,
                a:hover,
                a:focus {
                    color: $brand-red;
                }
            }

            &:last-child > a {
                // align last-li item with the topbar
                padding-right: 0;
            }
        }
    }
}
