/* ==========================================================================
   # Base Page Syles
   ========================================================================== */

body {
  height: 100%;
  overflow: auto;
}

.container {
  @include container-fixed;

  @media (min-width: $screen-sm-min) {
    width: $container-sm;
  }
  @media (min-width: $screen-md-min) {
    width: $container-md;
  }
  @media (min-width: $screen-lg-min) {
    width: $container-lg;
  }
  @media (min-width: $screen-xlg-min) {
    width: $container-xlg;
  }
}

// Grey container
.container--grey {
  background-color: $brand-grey-base;

  // padding-top: floor(46px / 2);
  // padding-bottom: floor(46px / 2);
  //
  // @media (min-width: $screen-md-min) {
  //   padding-top: 46px;
  //   padding-bottom: 46px;
  // }
}

.contaier--fluid {
  @extend .container-fluid;
}

// .container.padding-none {
//   @media (min-width: $screen-md-min) {
//     padding: 0 0 0 0;
//   }
// }

// Remove the gutter-padding from columns
.no-gutter > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

// body.nieuws {
//   background-color: $brand-grey-base;
// }

body.te-koop-single {
  background-color: $brand-white;
}
body.post-type-archive-cb_cpt_nieuws {
  background-color: $brand-grey-base;
}

.news-contact-top-border {
  padding-top: 0;
  border-top: 1px solid $brand-grey-footer-seperator;
}

body.contact {
  background-color: $brand-grey-base;
}


.telephone-link {
  color: $brand-blue;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.or-call-us {
  .telephone-link {
    color: $brand-white;
  }
}
