/* ==========================================================================
   # Layouts Nieuwbouw & Renovatie
   ========================================================================== */

// Base Styles
//
.nieuwbouw-renovatie-gallery {
  height: 100%;
  margin: 0 0;

  @media(min-width: $screen-md-min) {
    margin: 0px -2px 0;
  }

  .nieuwbouw-renovatie__items {
    height: auto;

    .swiper-wrapper {
      margin-bottom: 80px;

      @media(max-width: $screen-sm-max) {
        margin-bottom: 0;
      }
    }
    .immo-pagination {
      margin-bottom: 24px;
    }

    @media(min-width: $screen-md-min) {
      .swiper-button-next,
      .swiper-button-prev {
        margin-top: -102px;
      }
    }

    @media(max-width: $screen-sm-max) {
      .immo-pagination,
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }
  }
}
