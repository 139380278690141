/* ==========================================================================
   # Error Header
   ========================================================================== */

// Error Header Base Styles
//
.error-header {
  &.swiper-container {
    position: relative;
    display: block;
    width: 100%;
    height: 608px;
    z-index: 0;

    .swiper-slide {
      background: $body-bg;
      background-position: center;
      background-size: cover;
      @include flexbox;
      @include justify-content(center);
      @include align-items(center);

      .swiper-slide__inner {
        @extend .container;

        .title {
          @include make-md-column(7);
          margin-top: -10px;

          .title__heading {
            font-weight: $light-font-weight;
            color: $brand-white;
            font-size: $font-size-h2;
            line-height: floor(($font-size-h2 * $line-height-base));
          }
          .title__link {
            color: $brand-white;
            font-weight: $bold-font-weight;
            text-decoration: none;
            // border-bottom: 3px $brand-white dotted;
            @include vendor(transition, $brand-transition);

            background-image: linear-gradient(to right, $brand-white 66%, rgba($brand-white, 0) 0%);
            background-position: 0 bottom;
            background-size: 16px 2px;
            background-repeat: repeat-x;

            &:hover {
              color: transparentize($brand-white, .4);
              text-decoration: none;
              // border-bottom: 3px dotted transparentize($brand-white, .7);
              background-image: linear-gradient(to right, rgba($brand-white, .6) 66%, rgba($brand-white, 0) 0%);
            }
          }
        }
      }
      // subtle gradient-overlay
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include vendor(background, linear-gradient(to bottom, rgba($brand-white, 0) 0%,rgba($gray-base, .7) 80%));
      }
    }
  }
}

// Home Swiper Slider From: screen-sm-min
//
@media (min-width: $screen-sm-min) {

  .error-header {
    &.swiper-container {
      height: 408px;

      .swiper-slide {
        .swiper-slide__inner {
          // position: relative;
      		// top: -60px;
          .title {
            .title__heading {
              font-size: $font-size-h1;
              line-height: floor(($font-size-h1 * $line-height-base));
            }
            .title__link {
              &:hover {
              }
            }
          }
        }
      }
    }
  }
}

// Home Swiper Slider From: screen-md-min
//
@media (min-width: $screen-md-min) {

  .error-header {
    &.swiper-container {
      // height: 492px;
      height: 554px;

      .swiper-slide {
        .swiper-slide__inner {
          // position: relative;
      		// top: -84px;
          .title {
            .title__heading {
              font-size: $font-size-h1;
              line-height: floor(($font-size-h1 * $line-height-base));
            }
            .title__link {
              &:hover {
              }
            }
          }
        }
      }
    }
  }
}
