// LABELS
//
// Labels
// --------------------------------------------------

.label {
  display: inline;
  // padding: .2em .6em .3em;
  padding: 6px 8px;
  font-size: 86%;
  font-weight: bold;
  // margin: 4px 0;
  line-height: 1.4;
  color: $label-color;
  border-radius: 0;

}

// Add hover effects, but only for links
a.label {
  &:hover,
  &:focus {
    color: $label-link-hover-color;
    text-decoration: none;
    cursor: pointer;
  }
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label--primary {
  @include label-variant($brand-blue);
}
