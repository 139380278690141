/* ==========================================================================
   # Titles
   ========================================================================== */

// Base Titles
//
.title {
  margin: 0;

  &__subheading {
    display: block;
    font-size: $font-size-h5;
    line-height: floor(($font-size-h5 * $line-height-base));
    font-weight: $light-font-weight;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  &__heading {
    display: block;
    font-size: $font-size-h2;
    line-height: floor(($font-size-h2 * $line-height-base));
    font-weight: $bold-font-weight;
  }
}

// Align Text left
//
.title--align-left {
  & {
    text-align: left;
  }
}
// Align Text Center
//
.title--align-center {
  & {
    text-align: center;
  }
}
// Align Text Right
//
.title--align-right {
  & {
    text-align: right;
  }
}

.title--large {
  padding-top: 46px;
  padding-bottom: 46px;
}

.title--white {
  color: $brand-white;
  & > {
    color: inherit;
  }
}

.title--red {
  color: $brand-red;
  & > {
    color: inherit;
  }
}
