/* ==========================================================================
   # Realestate Single Gallery
   ========================================================================== */

// Realestate Gallery Swiper
//
.realestate-gallery {
  @include make-xs-column(12);

  .realestate-card__badge { display: none; }

  @media (min-width: $screen-md-min) {
    .realestate-card__badge {
      position: absolute;
      display: block;
      left: 4px;
      top: 20px;
      min-width: 120px;
      z-index: $zindex-badge;
      color: $brand-white;
      padding: 4px 46px 4px 16px;
      background-color: $brand-red;
      @include vendor(transition, $brand-transition);

      &:before {
        position: absolute;
        display: block;
        content: url(../images/badge-triangle.svg);
        left: 0;
        bottom: -18px;
      }

      > span {
        display: block;
        font-weight: $bold-font-weight;
      }
    }
  }

  .swiper-container {
    @include flexbox;
    @include align-items(center);
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;

    .swiper-slide {
      background-color: #f1f3f6;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
    }

    &.realestate-gallery__large-item {
      @include make-xs-column(12);
      @include make-md-column(6);
      @include make-lg-column(7);

      display: none;
      padding-left: 0;
      padding-right: 0;
      height: 554px;
      position: relative;

      .swiper-slide {
        display: block;
        width: 100%;
        height: 100%;
        background-color: $brand-grey-base;

        img {
          margin: auto;
          width: 100%; /* or any custom size */
          height: 100%;
          object-fit: contain;
        }
      }

      @media (min-width: $screen-md-min) {
          display: block;
          width: calc(50% - 25px);
          margin-right: 25px;
      }

      @media (min-width: $screen-lg-min) {
          width: calc(58.33333333% - 25px);
          margin-right: 25px;
      }
    }
    &.realestate-gallery__thumbs {
      @include make-xs-column(12);
      @include make-md-column(6);
      @include make-lg-column(5);
      float: left;
      display: block;
      padding-left: 0;
      padding-right: 0;
      height: 554px;

      .swiper-slide {
        position: relative;
        display: block;
        height: auto;
        width: 100%;
        margin: 0 auto;
        cursor: pointer;
        @include vendor(transition, $brand-transition);

        @media (min-width: $screen-md-min) {
          padding-left: 0;
          padding-right: 0;

          &:hover {
            @include vendor(transition, $brand-transition);
            cursor: pointer;

            &:before {
              content: "";
              display: block;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-color: rgba($brand-blue, .86);
            }
            &:after {
              content: url("../images/plus-icon.svg");
              display: block;
              position: absolute;
              width: 20px;
              height: 20px;
              vertical-align: middle;
              text-align: center;
              top: 46%;
              left: 46%;
            }
          }
        }
        &.swiper-slide--blank {
          @media (max-width: $screen-sm-max) {
            display: none;

            &:hover {
              &:before,
              &:after {
                display: none !important;
              }
            }
          }
          @media (min-width: $screen-md-min) {
            cursor: default !important;
            pointer-events: none;
            display: block;
            &:hover {
              &:before,
              &:after {
                display: none !important;
              }
            }
          }
        }
      }

      @media (min-width: $screen-md-min) {
        .swiper-button-next {
          top: 50%;
          right: 16px;
          left: auto;
          margin-top: -237px;
          height: 474px;
          background: rgba($brand-blue, .96);
          background-image: none !important;
          vertical-align: middle;
          width: 42px;

          &:before {
            position: absolute;
            content: url("../images/arrow-right-icon.svg");
            display: block;
            left: 15px;
            top: 50%;
            margin-top: -8px;
            width: 12px;
            height: 18px;
          }
        }
        .swiper-button-prev {
          top: 50%;
          left: 16px;
          right: auto;
          margin-top: -237px;
          height: 474px;
          background: rgba($brand-blue, .96);
          background-image: none !important;
          vertical-align: middle;
          width: 42px;

          &:before {
            position: absolute;
            content: url("../images/arrow-left-icon.svg");
            display: block;
            left: 15px;
            top: 50%;
            margin-top: -8px;
            width: 12px;
            height: 18px;
          }
        }
      }
    }
  }

  .realestate-gallery__print {
    display: none;

    img {
      vertical-align: top;
    }
  }

  // Hide thumbs on print and just show the images
  @media print {
    .swiper-container {
      display: none !important;
    }

    .realestate-gallery__print {
      display: block;
      font-size: 0; // Reset for removing whitespace

      img {
        display: inline-block;
        max-width: 50%;
        max-height: 320px;
      }
    }

    .realestate-card__badge {
      position: static;

      &:before {
        position: static;
      }
    }
  }

}

@media (max-width: $screen-sm-max) {
  .realestate-gallery {
    padding: 0;
    margin-top: 30px;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
