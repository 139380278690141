/* ==========================================================================
   # Single Content Module
   ========================================================================== */


// .single-content {
//   position: relative;
//   @extend .container;
//   // background: red;
//
//   .single-content__inner {
//     @include make-row();
//
//     .single-content-wrapper {
//       background: red;
//       @include make-xs-column(12);
//       @include make-md-column(10);
//       float: none !important;
//       margin: 0 auto;
//     }
//   }
// }


// Single Content Module Base Styles
//
.single-content {
  @extend .container-fluid;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background: $brand-white;
  display: table;

  .single-content__inner {
    @include make-row();
    display: table-cell;
    vertical-align: middle;

    .single-content__contents {
      @extend .container;
      text-align: left;

      .single-content-row {
        @include make-row();

        .single-content-row__inner {
          @include make-xs-column(12);
          @include make-md-column(8);
          float: none !important;
          margin: 0 auto;

          .single-content-article {
            // article styles here
            padding-top: 46px;
            padding-bottom: 20px;

            img {
              display: block;
              width: 100%;
              max-width: 100%;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

.single-gallery__thumbs {
  position: relative;
  display: block;
  height: 100%;
  background: red;

  .swiper-slide {
    height: 336px;

    img {
      display: block;
      height: 240px;
    }
  }
}
