/* ==========================================================================
   # Leader
   ========================================================================== */

// Base Syles
//
.leader {
  @extend .container;
  position: relative;
  display: block;

  .leader__inner {
    @include make-row;

    .leader__heading {
      @include make-md-column(4);

      .title {
        margin-top: 3px; // align with lead
        @media (max-width: $screen-sm-max) {
          margin-bottom: 24px;
        }
      }
    }

    .leader__lead {
      @include make-md-column(8);
      .leader-usp {
        @include make-row;

        .leader-usp__item {
          @include make-md-column(4);
          display: block;
          padding-top: 24px;

            @media (max-width: $screen-sm-max) {
              padding-top: 6px;
              padding-bottom: 6px;

            .im--custom {
              margin-top: -9px;
            }
          }
        }
      }
    }
  }
}
// Background color of container
.leader--grey {
  background-color: $brand-grey-base;
}
// About padding
.leader--home {
  padding-top: 46px;
  padding-bottom: 46px;

  @media (min-width: $screen-lg-min) {
    padding-left: 46px;
    padding-right: 46px;
  }

  .leader__inner {
    // border-top: 1px solid $brand-seperator-default;

    .leader__heading {
      // padding-left: 0 !important;
      // padding-right: 0 !important;
    }
    .leader__lead {
      // padding-top: 46px;
    }
  }
}


// About padding
.leader--about {
  padding-top: 46px;
  padding-bottom: 46px;

  @media (min-width: $screen-lg-min) {
    // padding-left: 46px;
    padding-right: 46px;
  }

  .leader__inner {
    border-top: 1px solid $brand-seperator-default;

    .leader__heading {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .leader__lead {
      padding-top: 46px;
    }
  }
}


// .home-about-intro {
//   @media (min-width: $screen-md-min) {
//     padding-right: 46px;
//     padding-left: 46px;
//   }
//
//   .home-about-intro__left-heading {
//     font-size: ($font-size-base * 1.5);
//     line-height: $headings-line-height;
//   }
// }
