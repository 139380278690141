/* ==========================================================================
   # Realestate Card
   ========================================================================== */

// Base Styles
//
.realestate-card {
  color: $brand-blue;
  text-decoration: none;
  @include vendor(transition, $brand-transition);

  &:hover {
    color: $brand-blue;
    text-decoration: none;
    margin-top: -12px;

    .realestate-card__inner > .realestate-card__image > .realestate-card__badge {
      top: 40px;
    }
  }

  .realestate-card__inner {

    .realestate-card__image {
      position: relative;
      display: flex;
      width: 100%;
      height: 270px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #e0e4eb;

      .realestate-card__heading {
        position: absolute;
        bottom: 35px;

        left: 15px;
        color: $brand-white;
        @include vendor(text-shadow, 0 2px 4px rgba($gray-base, .5));
      }

      .realestate-card__badge {
        position: absolute;
        display: block;
        left: -12px;
        top: 20px;
        min-width: 120px;
        z-index: $zindex-badge;
        color: $brand-white;
        padding: 4px 46px 4px 16px;
        background-color: $brand-red;
        @include vendor(transition, $brand-transition);

        &:before {
          position: absolute;
          display: block;
          content: url(../images/badge-triangle.svg);
          left: 0;
          bottom: -18px;
        }

        > span {
          display: block;
          font-weight: $bold-font-weight;

          &.with-padding {
            padding: 12px 0;
          }
        }
      }
    }

    .realestate-card__info {
      border: 1px solid $brand-grey-lighter;
      border-top: 0;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      background: $brand-white;

      ul.list-inline {
        display: table;
        margin: 0 auto;
        text-align: center;
        height: 40px;

        li {
          display: table-cell;
          padding-left: 12px;
          padding-right: 12px;
          text-align: center;
          vertical-align: middle;
        }
        li.vertical-divider {
          position: relative;
          top: 0px;
          width: 1px;
          padding-left: 0px;
          padding-right: 0px;
          border-right: solid 1px $brand-blue;
          height: auto;
        }
      }
    }
  }
}

// Large Card
//
.realestate-card--large {
  margin-top: 15px;
  margin-bottom: 15px;


  @media(min-width: $screen-md-min) {

    height: 270px;
    overflow: hidden;

    &:hover {
      color: $brand-blue;
      text-decoration: none;
      margin-top: 15px;
      @include vendor(box-shadow, none);

      .realestate-card__inner > .realestate-card__image > .realestate-card__badge {
        top: 20px;
      }
    }

    .realestate-card__image {
      display: block;
      position: relative;
      max-width: 290px !important;
      width: 290px !important;
      background-size: cover;

      .realestate-card__heading {
        display: none;
      }
    }

    .realestate-card__info {
      border: none !important;
      padding: 10px 15px;

      .realestate-card__text-inner {
        max-height: 180px;
        margin-bottom: 12px;
        margin-top: 0;
        overflow: hidden;
      }
      .realestate-options {
        float: left;
        margin-top: 2px;

        .realestate-options__item:first-child {
          padding-left: 0;
        }
      }
    }

    .realestate-card__seperator {
      margin: 15px 0;
    }
  }

  @media(max-width: $screen-sm-max) {

    & {
      width: 100%;
      display: block;
    }

    .realestate-card__image {
      position: relative;
      display: block;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      height: 270px;
      background-size: cover;
    }
    .realestate-card__info {
      border: 2px solid $brand-grey-lighter;
      border-top: 0;
      width: 100%;
      display: inline-block;

      .realestate-card__text-inner {
        display: none;
      }
      .list-inline.pull-left {
        float: none !important;
      }

      a.btn.btn--red {
        display: none;
      }
    }
  }
}
