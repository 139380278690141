/* ==========================================================================
   # Realestate Options
   ========================================================================== */

.realestate-options {
  display: table;
  margin: 0 auto;
  text-align: center;
  height: 40px;
  @include list-unstyled;

  .realestate-options__item {
    display: table-cell;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    vertical-align: middle;
  }

  .realestate-options__divider {
    position: relative;
    display: table-cell;
    top: 0px;
    width: 1px;
    padding-left: 0px;
    padding-right: 0px;
    border-right: solid 1px $brand-blue;
    height: auto;
  }

  &.realestate-options--max {
    max-width: 360px;
    background: red;
  }
}
