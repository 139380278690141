/* ==========================================================================
   # GravityForms
   ========================================================================== */

 // GravityForms :: Contact
 //
 #gform_1 {
   .validation_error {
     @extend .small;
     color: $brand-red;
   }
   .gform_body {
     .gform_fields { //ul
       list-style: none;
       padding-left: 0;
       padding-right: 0;

       .gfield { //li
         @extend .form-group;
         @extend .form-group--grey;
         width: 100%;
         margin-bottom: 15px;

         input,
         textarea {
           @extend .form-control;
         }
         .gfield_label {
           @extend .small;
         }
         &.gfield_error {

           .validation_message {
             display: block;
             color: $brand-red;
             position: relative;
             margin-top: 0;
             margin-left: 0;
             top: -35px;
             padding-left: 20px;
             background: $brand-grey-base;
             cursor: pointer;
           }
         }
         &#field_1_1,
         &#field_1_4,
         &#field_1_5,
         &#field_1_6 {
           float: left;
           width: 100%;

           @media (min-width: $screen-md-min) {
             width: 50%;
           }
           &:first-child,
           &:nth-child(3){
             @media (min-width: $screen-md-min) {
               padding-right: 14px;
             }
           }
           &:last-child {
             @media (min-width: $screen-md-min) {
               padding-left: 14px;
             }
           }
         }
       }
     }
   }
   .gform_footer {
     @extend .btn-group;
     @extend.pull-right;

     margin-top: 30px;
     margin-bottom: 46px;

     input[type="submit"] {
       @extend .btn;
       @extend .btn--blue;
       @extend .btn--bold;
     }

     .gform_ajax_spinner {
       display: none !important;
     }
   }
 }

 // GravityForms Field :: Make Appointment
 //
 #gform_2 {
   .validation_error {
     @extend .small;
     color: $brand-red;
    //  margin-bottom: 10px !important;
    display: none;
   }
   .gform_body {
     .gform_fields { //ul
       list-style: none;
       padding-left: 0;
       padding-right: 0;
       width: 100%;

       .gfield { //li
         margin-top: 0;

         .ginput_container {
           @extend .form-group;

           input {
             @extend .form-control;
              height: 44px;
              padding-top: 1px;
              padding-right: 18px;
              padding-bottom: 1px;
              padding-left: 18px;

              &,
              &:hover,
              &:focus {
                outline: 0;
                border: 0;
              }
           }
           textarea {
             @extend .form-control;
             height: 44px;
             padding-top: 10px;
             padding-right: 18px;
             padding-bottom: 10px;
             padding-left: 18px;

             &,
             &:hover,
             &:focus {
               outline: 0;
               border: 0;
             }
           }
         }
         .gfield_label {
           @extend .small;
           color: $brand-white !important;

           .gfield_required {
             color: $brand-red !important;
           }
         }
         &.gfield_error {
          margin-bottom: -25px;

           .validation_message {
             display: block;
             color: $brand-red;
             position: relative;
             margin-top: 0;
             margin-left: 0;
             padding-left: 20px;
             top: -48px;
             background: $brand-white;
             cursor: pointer;
           }
         }
       }
     }
   }
   .gform_footer {
     @extend .btn-group;
     @extend .btn-group-auto-width;
     margin-bottom: 0;

     input[type="submit"] {
       @extend .btn;
       @extend .btn--red;
       @extend .btn--bold;
     }
     .gform_ajax_spinner {
       display: none !important;
     }
   }

   @media(max-width: $screen-sm-max) {
     .appointmentQuestions {
       margin-bottom: 18px;
     }
   }

   @media(min-width: $screen-md-min) {

     .appointment-form-flexbox {
       @include flexbox;
       @include flex-direction(row);
       @include flex-wrap(wrap);
      //  @include align-items(center);
      //  @include justify-content(space-between);

       .appointment-form-flexbox__content {
         padding-left: 6px;
         padding-right: 6px;
       }
     }

     .appointmentFullname,
     .appointmentEmail,
     .appointmentTelephone,
     .appointmentPrefdate,
     .appointmentSubmit {
       @include flex-basis(50%);
       max-width: 50%;
     }
     .appointmentQuestions {
       @include flex-basis(50%);
       margin-bottom: 0;
     }
     .appointmentCall {
       @include flex-basis(50%);
     }

    .appointmentFullname { @include order(1); }
    .appointmentEmail { @include order(2); }
    .appointmentTelephone { @include order(3); }
    .appointmentPrefdate { @include order(4); }
    .appointmentQuestions { @include order(5); }
    .appointmentSubmit { @include order(6); }
    .appointmentCall { @include order(7); }

    .appointmentSubmit {
      top: 28px;
    }

   }

   @media(min-width: $screen-lg-min) {

     .appointment-form-flexbox {
       @include flexbox;
       @include flex-direction(row);
       @include flex-wrap(wrap);
       @include align-items(center);
       @include justify-content(space-between);
     }

     .appointmentFullname,
     .appointmentEmail,
     .appointmentTelephone,
     .appointmentPrefdate,
     .appointmentSubmit {
       @include flex-basis(20%);
       max-width: 20%;
     }
     .appointmentQuestions {
       @include flex-basis(60%);
       margin-bottom: 0;
     }
     .appointmentCall {
       @include flex-basis(40%);
       top: 5px;
       position: relative;
       margin-bottom: 0;

       .or-call-us {
         margin-top: 0;
       }
     }

    .appointmentQuestions { @include order(6); }

    .appointmentSubmit {
      @include order(5);
      top: 6px;
    }
    .appointmentPrefdate {
      .select {
        top: 3px;
        .select-styled {
          height: 42px;
        }
      }
    }
   }
 }

 // #gform_wrapper_2 {
 //   &.gform_validation_error {
 //     .appointmentFullname,
 //     .appointmentEmail {
 //       margin-top: 10px;
 //     }
 //    //  .appointmentTelephone { margin-top: -15px; }
 //   }
 // }
