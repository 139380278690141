/* ==========================================================================
   # Contact Page
   ========================================================================== */

// Contact Base Styles
//
.contact-container {
  @extend .container;
  background-color: $brand-white;
  border: 1px solid $brand-seperator-default;
  margin: 46px auto;

  .contact-container__row {
    @include make-row;

    .contact-wrapper {
      // @include flexbox;
      // @include align-items(center);
      // @include justify-content(flex-start);

      @include make-xs-column(12);

      @media (min-width: $screen-md-min) {
        padding-left: 0;
      }

      .contact-gallery {
        @include make-xs-column(12);
        @include make-md-column(6);

        @media (min-width: $screen-md-min) {
          padding-left: 0;
        }

        .contact-gallery__inner {

        }
        // margin-top: 46px;
        // margin-bottom: 46px;

        // @media (min-width: $screen-md-min) {
        //   height: 432px;
        // }
      }
      .contact-form {
        @include make-xs-column(12);
        @include make-md-column(6);
        // background: $brand-blue;
        height: 100%;

        .contact-form__inner {
          padding: 0px;
          @media (min-width: $screen-md-min) {
            padding: 46px;

            .social-icons {
              margin-bottom: 24px;
            }
          }

          .contact-form-flexbox {

            .contact-form-flexbox__content {
              float: left;

              @media (min-width: $screen-md-min) {
                width: 50%;
              }
              &:first-child {
                @media (min-width: $screen-md-min) {
                  padding-right: 14px;
                }
              }
              &:last-child {
                @media (min-width: $screen-md-min) {
                  padding-left: 14px;
                }
              }
            }
          }
          .btn-group {
            @media (max-width: $screen-md-max) {
              padding-bottom: 46px;
            }
          }
        }
      }
    }
  }
}

.contact-maps-container {
  @extend .container;

  .contact-container__row {
    @include make-row;

    .contact-wrapper {
      @include make-xs-column(12);
      padding-left: 0;
      padding-right: 0;

      .contact-gmap {
        margin-bottom: 46px;
        display: block;
        width: 100%;
        height: 430px;
      }
    }
  }
}
