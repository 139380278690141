/* ==========================================================================
   # About Profiles
   ========================================================================== */

// About Profiles Base
//
.about-profiles {
  @extend .container;
  display: block;
  position: relative;
  // padding-top: 46px;
  margin-bottom: 0;

  .about-profiles__inner {
    position: relative;
    display: table;
    margin-left: auto;
    margin-right: auto;

    .about-profile__image {
      display: table-cell;
      margin-left: 10px;
      margin-right: 10px;
      float: left;
      max-width: 200px;

      img {
        border: 10px solid $brand-white;
      }
    }
  }
  &:before {
    position: absolute;
    content: " ";
    display: block;
    top: 0;
    left: 0;
    background-color: $brand-grey-base;
    height: 100%;
    width: 100%;
  }
}

.about-profile-info {
  text-align: center;

  span {
    display: block;
  }

  &__name {
    margin-bottom: 12px;
  }

  &__telephone,
  &__email {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 14px;
  }
}

// About Profiles From: screen-md-min
//
@media (min-width: $screen-md-min) {
  .about-profiles {
    margin-top: -100px;
    padding-top: 0;

    &:before {
      top: 90px;
    }
  }
}

// About Profiles Until: screen-sm-max
//
@media (max-width: $screen-sm-max) {
  .about-profiles {
    margin-top: -100px;
    padding-top: 0;

    .about-profile__image {
      display: block;
      float: none;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 0 !important;
      margin-left: 0 !important;
      padding-left: 6px;
      padding-right: 6px;
      width: 50%;
      max-width: 50%;

      .img-responsive {
        margin: 0 auto;
      }
    }
  }
}
