/* ==========================================================================
   # Realestate Archive
   ========================================================================== */

// Realestate Archive Base
//
.realestate-archive {
  padding-bottom: 46px;

  .immo-filter {
    margin-top: 46px;
    margin-bottom: 31px;
  }
  .immo-sidebar { }

  .immo-sidebar--mobile {
    @include make-xs-column(12);
    padding-bottom: 46px;
  }

  .facetwp-pager {
    text-align: center;

    .facetwp-page {
      margin-top: 24px;
    }
  }
}

// @media (min-width: $screen-md-min) {
//   .immo-sidebar--mobile { display: none;}
// }
// @media (max-width: $screen-md-max) {
//   .immo-sidebar--desktop { display: none;}
// }

.immo-sidebar {

  // Title Filter
  //
  .title--sidebar-filter {
    padding-top: 15px;
    padding-bottom: 46px;

    @media (max-width: $screen-md-max) {
      display: none;
    }
    @media (min-width: $screen-lg-min) {
      display: block;
    }
  }

  // Mobile Button
  //
  .btn-group {
    @media (max-width: $screen-md-max) {
      display: block;
      padding-bottom: 74px;
    }
    @media (min-width: $screen-lg-min) {
      display: none;
    }
  }

  .immo-sidebar-list {
    @media (max-width: $screen-md-max) {
      display: none;
    }
    &.open {
      display: block;
    }
  }
}
