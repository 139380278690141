/* ==========================================================================
   # Contact Gallery
   ========================================================================== */

// Realestate Gallery Swiper
//
.contact-gallery {
  margin-bottom: 44px;

  .swiper-container {
    // @include flexbox;
    // @include align-items(center);
    width: 100%;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 0;

    .swiper-slide {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      background-color: #f1f3f6;
    }

    &.contact-gallery__large-item {
      // @include make-xs-column(12);
      // @include make-md-column(6);
      // @include make-lg-column(7);
      // display: none;
      padding-left: 0;
      padding-right: 0;
      // padding-right: 20px;
      height: 430px;

      .swiper-slide {
        display: block;
        width: 100%;
        height: 100%;
        background-color: $brand-grey-base;
      }

      @media (min-width: $screen-md-min) {
        // float: left;
        display: block;
      }
    }
  }

  .contact-gallery__thumbs {
    // @include make-xs-column(12);
    // @include make-md-column(6);
    // @include make-lg-column(5);
    // float: left;
    // margin-left: -15px;
    // margin-right: -15px;
    display: none;
    padding-left: 0;
    padding-right: 0;
    height: 148px;

    @media (min-width: $screen-md-min) {
      display: block;
    }

    .contact-gallery__thumb {
      position: relative;
      float: left;
      margin: 20px auto;
      height: 148px;
      width: 100%;
      cursor: pointer;
      background-color: #f1f3f6;

      @include vendor(transition, $brand-transition);

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-height: 148px;
        width: auto;
        margin: 0 auto;
        z-index: 90;
      }

      @media (min-width: $screen-md-min) {
        padding-left: $grid-gutter-width / 4.0;
        padding-right: $grid-gutter-width / 4.0;

        width: 33.333%;

        &:hover {
          @include vendor(transition, $brand-transition);
          cursor: pointer;

          &:before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba($brand-blue, .86);
            z-index: 99;
          }
          &:after {
            content: url("../images/plus-icon.svg");
            display: block;
            position: absolute;
            width: 20px;
            height: 20px;
            vertical-align: middle;
            text-align: center;
            top: 46%;
            left: 46%;
            z-index: 100;

          }
        }
      }
    }
  }
}
