/* ==========================================================================
   # FacetWP Custom Styles
   ========================================================================== */

// Fselect Field
//
.facetwp-type-fselect,
// .facetwp-type-number_range_options {
//   margin-bottom: 0;
// }

// Fselect Field Base Styles
//
.fs-wrap {
  display: block;
  cursor: pointer;
  width: 100%;
  min-width: 140px;
  max-width: 100%;
  margin: 0;

  &:hover,
  &:focus {
    outline: none;
  }

  .fs-label-wrap {
    position: relative;
    border: 0;
    padding: 10px 18px;
    cursor: default;
    background: $brand-white;
    @include vendor(user-select, none);

    .fs-label {
      width: 92%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }
  .fs-dropdown {
    position: absolute;
    margin-top: 0;
    width: 100%;
    border: none;
    background-color: $brand-white;
    @include vendor(user-select, none);
    @include box-shadow(0 6px 12px rgba($gray-base, .175));

    .fs-options {
      max-height: 200px;
      overflow: auto;

      .fs-option {
        padding: 12px 16px;

        &:hover {
          background: $brand-grey-lighter;
        }
      }
    }
  }
}

.fs-arrow {
  position: absolute;
  width: 14px;
  height: 14px;
  top: 0;
  right: 16px;
  bottom: 0;
  margin: auto;
  background: $brand-white;

  &:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    margin-top: 0;
    float: right;
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    // padding-left: 8px;
  }
}

.facetwp-facet {
  .fs-arrow:after {
    content: "\f107";
  }
}

.facetwp-facet.open {
  .fs-arrow:after {
    content: "\f106";
  }
}

// FacetWP SPecial Checkboxes
//
.fs-wrap.multiple {
  position: relative;

  .fs-search {
    width: 100%;
    display: block;

    input {
      outline: 0;
      width: 100%;
      @extend .form-control;
      background-color: $brand-grey-base;
      border: none;
      border-radius: 0;
      padding: 6px 18px;
    }
  }
  .fs-option {
    .fs-checkbox {
      position: relative;
      display: block;
      margin-right: 10px;

      i {
        position: absolute;
        display: block;
        top: 2px;
        left: 0;
        // width: 20px;
        // height: 20px;
        width: 18px;
        height: 18px;
        outline: none;
        border: 1px solid $brand-seperator-default;
        background: $brand-white;
        border-radius: 0;
      }
    }
    .fs-option-label {
      padding-left: 30px;
    }
    &.selected {
      i:after {
        position: absolute;
        display: block;
        content: "\f00c";
        top: 1px;
        left: 1px;
        width: 14px;
        height: 14px;
        font-family: "FontAwesome";
        font-size: 12px;
        text-align: center;
        opacity: 1;
      }
    }
  }
}

.facetwp-type-fselect .fs-wrap,
.facetwp-type-fselect .fs-dropdown {
  min-width: 100px;
  width: 100% !important;
}

.facetwp-type-fselect .fs-dropdown .fs-options,
.fs-wrap .fs-dropdown .fs-options {
  max-height: none;
}

.fs-wrap.multiple .fs-dropdown .fs-options {
  max-height: 240px !important;
}

.fs-dropdown { z-index: 1; }
.fs-dropdown.hidden { z-index: 0; }


.facetwp-number-range-option-wrap,
.facetwp-radio,
.facetwp-checkbox {
  margin: 12px 0;
  color: $brand-blue;
}

// Hide the FacetWP counter
.facetwp-counter {
  display: none;
}


input[type="radio"] {
  &[checked] {
    i.immo-radio__icon {
      &:after {
        opacity: 1;
      }
    }
  }
}
