/* ==========================================================================
   # Immo Custom Icons
   ========================================================================== */
.im {
  // position: relative;
  display: inline-block;
  // width: 20px;
  // height: 20px;

  &.im--custom {
    float: left;
    margin-top: 3px;
    margin-right: 15px;
    text-align: center;
  }
}

.im--larger {
  height: 25px;

  &:before {
    display: block;
    height: 25px;
  }
}

.im-user:before {
  position: relative;
  content: url(../images/login-user-icon.svg);
}
.im-user--mobile:before {
  position: relative;
  content: url(../images/login-user-icon--mobile.svg);
  top: 6px;
  margin-right: 2px;
}
.im-phone:before {
  position: relative;
  content: url(../images/phone-book-icon.svg);

}
.im-mobile:before {
  position: relative;
  content: url(../images/mobile-icon-2.svg);
  // top: -6px;
  // width: 27px;
  // height: 40px;
  // background: red;
}
.im-sold:before {
  position: relative;
  content: url(../images/sold-icon-2.svg);
  // width: 27px;
  // height: 40px;
  // background: red;
}
.im-car:before {
  position: relative;
  content: url(../images/car-icon.svg);
  top: 2px;
  margin-right: 5px;
}
.im-m2:before {
  position: relative;
  // content: url(../images/m2-icon.svg);
  content: url(../images/m2-icon--2.svg);
  top: 2px;
  margin-right: 5px;
}
.im-bed:before {
  position: relative;
  content: url(../images/bed-icon.svg);
  top: 2px;
  margin-right: 5px;
}
.im-bath:before {
  position: relative;
  content: url(../images/bath-icon.svg);
  top: 2px;
  margin-right: 5px;
}
.im-desktop:before {
  position: relative;
  content: url(../images/desktop-icon-2.svg);
  // width: 27px;
  // height: 40px;
  // background: red;
}
.im-blogpost:before {
  position: relative;
  // content: url(../images/blogpost-icon.svg);
  content: url(../images/blogpost-icon--grey.svg);
  top: 6px;
  margin-right: 10px;
}
.im-blogpost--white:before {
  position: relative;
  // content: url(../images/blogpost-icon.svg);
  content: url(../images/blogpost-icon--white.svg);
  top: 6px;
  margin-right: 10px;
}
.im-pinpoint:before {
  position: relative;
  content: url(../images/pinpoint-icon.svg);
  top: 2px;
  margin-right: 5px;
}
.im-checked-icon--small:before {
  position: relative;
  content: url(../images/checked-icon--small.svg);
  top: 2px;
  margin-right: 5px;
}


// Social Icons
.im-whatsapp:before {
  position: relative;
  content: url(../images/whatsapp-icon.svg);
  top: 6px;
}
.im-facebook:before {
  position: relative;
  content: url(../images/facebook-icon.svg);
  top: 6px;
}
.im-facebook--small {
  &:before{
    position: relative;
    content: url(../images/facebook-icon--small.svg);
    top: 6px;
  }
  &:hover {
    &:before {
      content: url(../images/facebook-icon--small--hover.svg);
    }
  }
}
.im-facebook--color:before {
  position: relative;
  content: url(../images/facebook-icon--color.svg);
}
.im-facebook--mobile:before {
  position: relative;
  content: url(../images/facebook-icon--mobile.svg);
  top: 3px;
}
.im-linkedin:before {
  position: relative;
  content: url(../images/linkedin-icon.svg);
  top: 6px;
}
.im-linkedin--small {
  transition: $brand-transition;
  &:before {
    position: relative;
    content: url(../images/linkedin-icon--small.svg);
    top: 7px;
  }
  &:hover {
    &:before {
      content: url(../images/linkedin-icon--small--hover.svg);
    }
  }
}
.im-linkedin--color:before {
  position: relative;
  content: url(../images/linkedin-icon--color.svg);
}
.im-linkedin--mobile:before {
  position: relative;
  content: url(../images/linkedin-icon--mobile.svg);
  top: 3px;
}
.im-twitter:before {
  position: relative;
  content: url(../images/twitter-icon.svg);
  top: 6px;
}
.im-twitter--small:before {
  position: relative;
  content: url(../images/twitter-icon-small.svg);
  top: 6px;
}
.im-instagram:before {
  position: relative;
  content: url(../images/instagram-icon.svg);
  top: 6px;
}
.im-instagram--small {
  transition: $brand-transition;
  &:before {
    position: relative;
    content: url(../images/instagram-icon--small.svg);
    top: 6px;
    transition: $brand-transition;
  }
  &:hover {
    &:before {
      content: url(../images/instagram-icon--small--hover.svg);
    }
  }
}
.im-instagram--color:before {
  position: relative;
  content: url(../images/instagram-icon--color.svg);
}
.im-instagram--mobile:before {
  position: relative;
  content: url(../images/instagram-icon--mobile.svg);
  top: 4px;
}
.im-email:before {
  position: relative;
  content: url(../images/email-icon.svg);
  top: 6px;
}
.im-print:before {
  position: relative;
  content: url(../images/print-icon.svg);
  top: 6px;
}

// Icons on single-page

.im-feature {

  &.im-gebouw:before {
    position: relative;
    display: inline-block;
    content: url(../images/gebouw-icon.svg);
    top: 2px;
    left: 0;
    width: 24px;
  }

  &.im-indeling:before {
    position: relative;
    display: inline-block;
    // content: url(../images/gebouwindeling-icon.svg);
    content: url(../images/indeling-icon.svg);
    top: 2px;
    left: 0;
    width: 24px;
  }

  &.im-stedenbouw:before {
    position: relative;
    display: inline-block;
    content: url(../images/stedenbouw-icon.svg);
    top: 2px;
    left: 0;
    width: 24px;
  }

  &.im-terrain-parking:before {
    position: relative;
    display: inline-block;
    content: url(../images/terrain-parking-icon.svg);
    top: 4px;
    left: 0;
    width: 24px;
  }
  &.im-energie:before {
    position: relative;
    display: inline-block;
    content: url(../images/energie-icon.svg);
    top: 4px;
    left: 0;
    width: 24px;
  }
  &.im-financieel:before {
    position: relative;
    display: inline-block;
    content: url(../images/financieel-icon.svg);
    top: 4px;
    left: 0;
    width: 24px;
  }
}

// Foooter Icons
.social-icons {
	margin: 0;
	padding: 0;

  > li {
    list-style: none;
  	display: inline-block;
  }
}
