.search-block {
  position: relative;
  background: $brand-grey-base;

  .search-block__input {
    width: 100%;
    padding: 18px;
    margin-top: 3px;
    border: 0;
    font-size: 20px;
    font-weight: 700;
    font-family: $font-family-sans-serif;
    outline: none;
    color: $brand-primary;
    background: transparent;

    &::-webkit-input-placeholder {
      color: $brand-primary;
    }

    &s:-ms-input-placeholder {
      color: $brand-primary;
    }
  }

  .search-block__btn {
    width: 20px;
    height: 22px;
    border: 0;
    background: none;
    background-image: url(../images/search-icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    outline: none;

    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .search-block__help {
    color: $brand-secondary;
    font-family: $font-family-sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: .49px;
  }

  &.search-block--light {
    .search-block__input {
      border-bottom-color: #fff;
      color: #fff;

      &::-webkit-input-placeholder {
        color: #fff;
      }

      &s:-ms-input-placeholder {
        color: #fff;
      }
    }

    .search-block__btn {
      background-image: url(../images/search-icon-white.svg);
    }
  }

  &.search-block--with-margin {
    @media (min-width: $screen-md-min) {
      margin: 57px 0 75px;
    }
  }

  @media (min-width: $screen-md-min) {
    background: none;

    .search-block__input {
      font-size: 3.6rem;
      border-bottom: dashed 2px $brand-primary;
      margin-bottom: 21px;
      padding-left: 0;
      padding-right: 40px;
    }

    .search-block__btn {
      top: 40px;
      transform: none;
    }
 }
}

