.single-content-photogallery {

  .single-gallery-lightbox {
    margin-top: 24px;
    margin-bottom: 46px;

    .cbp-caption-defaultWrap {
      @include vendor(transition, $brand-transition);
      &:before { @include vendor(transition, $brand-transition); }

      > img {
        margin-bottom: 0 !important;
      }
    }

    .cbp-caption:hover {

      .cbp-caption-defaultWrap {
        @include vendor(transition, $brand-transition);
        cursor: pointer;

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background-color: rgba($brand-blue, .86);
          @include vendor(transition, $brand-transition);
        }
        &:after {
          content: url("../images/plus-icon.svg");
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          vertical-align: middle;
          text-align: center;
          top: 45%;
          left: 45%;
        }
      }
    }
  }
}
