/* ==========================================================================
   # Page Bottom
   ========================================================================== */

// Archive Blogpost
//
.blogpost-archive {
  display: block;
  // padding-bottom: 46px;

  @media(min-width: $screen-lg-min) {
    padding-bottom: 46px;
  }

  .blogpost-archive__inner {
    display: inline-block;

    .blogpost-archive__image {
      display: inline-block;
      position: relative;
      float: left;
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
      height: 270px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      @media(max-width: $screen-md-max) {
        display: block;
        float: none;
        width: 100%;
        height: 270px;
      }
    }
    .blogpost-archive__content {
      display: block;
      background: $brand-white;
      border: 1px solid $brand-grey-footer-seperator;
      border-left: none;
      padding: 0 20px;
      height: 270px;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 26px;
        background: $brand-white;

        @media(max-width: $screen-md-max) {
          height: 30px;
        }
      }

      @media(max-width: $screen-sm-max) {
        width: 100%;
        height: auto;
        border-left: 1px solid $brand-grey-footer-seperator;
      }

      .blogpost-archive__heading {
        font-size: floor(($font-size-base * 1.4));
        font-weight: $headings-font-weight;
        display: block;
        line-height: 1.4;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 88px;

        @media(max-width: $screen-md-max) {
          text-align: center;
        }
      }
      .blogpost-archive__date {
        color: $brand-grey-seperator;
        font-weight: $bold-font-weight;

        @media(max-width: $screen-md-max) {
          text-align: center;
          display: block;
        }
      }
      .blogpost-archive__seperator {
        margin: 15px 0;
        border-top: 1px solid $brand-grey-footer-seperator;
      }
      .blogpost-archive__text {
        color: $brand-blue;
        overflow: hidden;
        position: relative;

        @media(max-width: $screen-md-max) {
          text-align: center;
        }
      }
    }
  }
}

// .contact-block {
//   display: table;
//   // table-layout: fixed;
//   max-height: 270px;
//   width: 100%;
//   padding-bottom: 46px;
//   background: red;
//
//   .contact-block__title {
//     display: table-cell;
//     vertical-align: middle;
//     padding: 30px;
//
//     h1 {
//       font-size: floor(($font-size-base * 1.4));
//       font-weight: $headings-font-weight;
//       line-height: 1.4;
//     }
//   }
//   &:after {
//     content: " ";
//     display: table;
//     position: absolute;
//     background-color: rgba($brand-blue, .86);
//     width: 100%;
//     height: 100%;
//     max-height: 270px;
//     top: 0;
//     left: 0;
//     // bottom: 0;
//   }
// }

.contact-block {
  position: relative;
  display: block;
  height: 270px;
  margin-bottom: 46px;

  .contact-block__title {
    position: absolute;
    left: 0;
    top: 50%;
    padding-right: 30px;
    padding-left: 30px;
    transform: translate(0, -50%);
    width: 100%;

    h1 {
      font-size: floor(($font-size-base * 1.4));
      font-weight: $headings-font-weight;
      line-height: 1.4;
      color: $brand-white;
    }
  }
  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 270px;
    top: 0;
    left: 0;
    background-color: rgba($brand-blue, .86);
    z-index: 0;
  }
}


.flex {
  @include flexbox;

  .flexbox {
    float: left;
    @include flex-wrap(wrap);
    @include justify-content(center);
  }
}
