/* ==========================================================================
   # Immo Filter
   ========================================================================== */

// Immo Filter Base
//
.immo-filter {
	position: relative;
	z-index: $zindex-dropdown;
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;

	.immo-filter__inner {
		padding: 15px;

		.immo-filter-flexbox {
			@include flex-wrap(wrap);

			.immo-filter-flexbox__content {
				margin-top: 6px;
				margin-bottom: 6px;

				&:nth-child(3),
				&:nth-child(4) {
					padding-left: 0;
					padding-right: 0;
				}

				.dropdown-toggle {
					text-align: left;

					> .fa.fa-angle-down {
						margin-top: 4px;
						float: right;
					}
				}

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

// Immo Filter From: screen-sm-min
//
@media (min-width: $screen-sm-min) {
	.immo-filter {

		.immo-filter__inner {

			.immo-filter-flexbox {

				.immo-filter-flexbox__content {
					margin-top: 6px;
					margin-bottom: 6px;

					&:nth-child(3) {
						@include make-sm-column(6);
						padding-left: 0;
						padding-right: 6px;
					}
					&:nth-child(4) {
						@include make-sm-column(6);
						padding-left: 6px;
						padding-right: 0;
					}
				}
			}
		}
	}
}

// Immo Filter From: screen-md-min
//
@media (min-width: $screen-md-min) {
	.immo-filter {
		.immo-filter__inner {

			.immo-filter-flexbox {

				.immo-filter-flexbox__content {

					&:nth-child(odd) {
						@include make-sm-column(6);
						padding-left: 0;
						padding-right: 6px;
					}
					&:nth-child(even) {
						@include make-sm-column(6);
						padding-left: 6px;
						padding-right: 0;
					}
				}
			}
		}
	}
}

// Immo Filter From: screen-lg-min
//
@media (min-width: $screen-lg-min) {
	.immo-filter {

		.immo-filter__inner {
			padding: 0px;

			.immo-filter-flexbox {
				@include flexbox;
				@include flex-wrap(nowrap);

				.immo-filter-flexbox__content {
					@include justify-content(space-between);
					margin-right: 6px;
					margin-left: 6px;

					&:nth-child(odd) {
						width: 100%;
						padding-left: 0;
						padding-right: 0;
					}
					&:nth-child(even) {
						width: 100%;
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}

// Immo Filter Home Specific
//
.immo-filter--home {
	margin-top: -416px;
	margin-bottom: 42px;

		@media (min-width: $screen-sm-min) {
			margin-top: -106px;
			margin-bottom: 0;
		}
		@media (min-width: $screen-md-min) {
			// margin-top: -148px;
			margin-top: -148px; //facetwp
			margin-bottom: 0;
		}

	.immo-filter__inner {
		@media (min-width: $screen-lg-min) {
			padding: 48px;
			padding-bottom: 26px;

			.immo-filter-flexbox {
				margin-top: -21px;
			}
		}
	}
}

// Immo Filter Red Background
//
.immo-filter--red {
	.immo-filter__inner {
		background-color: rgba($brand-red, .86);
	}
}

// Immo Filter Red Background
//
.immo-filter--grey {
	.immo-filter__inner {
		background-color: rgba($brand-grey-lighter, 1);
	}
}
