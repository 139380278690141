/* ==========================================================================
   # Footer
   ========================================================================== */

// padding-bottom: 10px;
.footer {
	background: $brand-footer;

	&__inner {
		// border-top: 3px solid $brand-base-grey-footer-seperator;

		.pages-footer__list {
			li {
				padding-bottom: 12px;
				padding-left: 0 !important;
				padding-right: 0 !important;

				a:hover {
					text-decoration: none;
				}
				.label--primary {
					background-color: transparentize($brand-blue, .5);
					font-size: $font-size-base;
					font-weight: $base-font-weight;
					&:hover {
						background-color: darken($brand-blue, .2);
					}
				}
			}
		}
	}
}

.footer-flexbox {
	@include flexbox;
	@include flex-wrap(wrap);

	@media(min-width: $screen-md-min) {
		@include flex-wrap(nowrap);
		@include align-content(center);
	}

	.footer-flexbox__content {
		padding-top: 11px;
		padding-bottom: 36px;

		@media(min-width: $screen-md-min) {
			@include justify-content(space-between);
			max-width: 460px;
			padding-left: 24px;
			padding-right: 24px;
		}

		&:first-child {
			@media(min-width: $screen-md-min) {
				padding-left: 0;
				min-width: 300px;
			}
		}

		&:nth-child(2) {
			@media(min-width: $screen-md-min) {
				min-width: 300px;
				max-width: 300px;
			}
		}

		&:last-child {
			@media(min-width: $screen-md-min) {
				// padding-right: 0;
				border-right: none;
				min-width: 380px;
			}
		}


		@media(min-width: $screen-md-min) {
			border-right: 1px solid $brand-grey-seperator;
		}

		.fb-page-container {
			@media(min-width: $screen-md-min) {
				margin-top: 32px;
			}
		}
	}
}

.copyright {
	padding: 26px 0;
	background: $brand-white;
	border-top: none;

	p {
		margin-bottom: 0;
	}
}


.vertical-divider {
	position: relative;
	top: 0px;
	width: 1px;
	padding-left: 0px;
	padding-right: 0px;
	border-right: solid 1px $brand-blue;
	height: auto;
}

// Issue: IMC-10
// more padding between footer button and text ('nieuwe zoekpodracht')
.searchquery {
	margin-top: 6px;
}
