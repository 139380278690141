/* ==========================================================================
   # Actions
   ========================================================================== */

// Actions Base Styles
//

.actions {
  position: relative;
  @extend .container;
  // padding-top: 46px;

  .actions__inner {
    @include make-row();

    .actions-contents {
      @include make-xs-column(12);
      // background: green;

      .action-list {
        display: block;
        border-top: 1px solid $brand-seperator-default;
        border-bottom: 1px solid $brand-seperator-default;
        padding-bottom: 46px;

        &:nth-child(odd) {
          border-bottom: none;
        }

        .action-list__item {
          position: relative;
          display: table;
          table-layout: fixed;
          width: 100%;
          margin-top: 4px;
          margin-bottom: 4px;

          vertical-align: middle;
          // padding: 15px;

          .action-list-title {
            @include make-xs-column(12);
            @include make-md-column(3);
            background-color: $brand-footer;
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            padding: 15px;
            border: 1px solid $brand-seperator-default;

            // @media (max-width: $screen-sm-max) {
            //   width: 100%;
            // }

          }
          .action-list-contents {
            @include make-xs-column(12);
            @include make-md-column(9);
            display: table-cell;
            padding: 15px;
            height: 80px !important;
            vertical-align: middle;
            background-color: $brand-grey-base;
            border: 1px solid $brand-seperator-default;
            overflow: hidden;

            &:after {
              content: " ";
              display: block;
              position: absolute;
              bottom: 0;
              left: 0;
              background-color: $brand-grey-base;
              width: 100%;
              height: 15px;
            }


            &.in {
              display: block !important;
              min-height: 80px;
              height: 100% !important;
              overflow: visible !important;
            }
          }
        }
      }
    }
  }
}

.action-list__header {
  // display: table;
  // table-layout: fixed;
  // vertical-align: middle;
  // width: 100%;

  .action-list__title {
    // background: lightgrey;
    @include make-xs-column(12);
    @include make-md-column(6);
    // display: table-cell;
    // vertical-align: middle;

    h2 {
      margin-bottom: 24px;
    }
  }
  .action-list__filter {
    @include make-xs-column(12);
    @include make-md-column(6);

    @media (min-width: $screen-md-min) {
      margin-top: 34px;
    }

    .action-list__filter-links {
      float: left;
      text-align: left;
      padding-bottom: 24px;

      @media (min-width: $screen-md-min) {
        float: right;
        text-align: right;
        padding-bottom: 0;
      }
    }
  }
}
