/* ==========================================================================
   # Sidebar Filter
   ========================================================================== */

.immo-sidebar-list {
  padding: 0;

  .immo-sidebar-list__item {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    border: none;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
    margin-top: 30px;
    margin-bottom: 30px;

    a {
      display: block;
      font-size: $font-size-h4;
      font-weight: $headings-font-weight;
      color: $brand-blue;
      text-decoration: none;
    }

    ul {
      list-style: none;
      padding: 0;
      width: 100%;
      // float: left;

      li {
        margin: 12px 0;
        padding: 0;
      }
    }

    &.active {
      background-color: transparent;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}

.immo-sidebar-list__item:first-child {
  margin-top: 0;
}
.immo-sidebar-list__item:last-child {
  margin-bottom: 0;
}

.list-toggle {
  a[aria-expanded="false"] {
    &:after {
      position: absolute;
      top: -2px;
      right: 10px;
      display: inline-block;
      font-size: 24px;
      content: "\f105";
      font-weight: normal;
      font-family: "FontAwesome";
      color: $brand-blue;
    }
  }
  a[aria-expanded="true"] {
    &:after {
      position: absolute;
      top: -2px;
      right: 10px;
      display: inline-block;
      font-size: 24px;
      content: "\f107";
      font-weight: normal;
      font-family: "FontAwesome";
      color: $brand-blue;
    }
  }
}
