/* ==========================================================================
   # Appointment
   ========================================================================== */

// Appointment Bar
//
.appointment-bar {
  @extend .container;
  display: block;
  position: relative;
  // background-color: $brand-grey-base; // moet deze kleur hier?

  @media(max-width: $screen-sm-max) {
    background-color: $brand-grey-base; // of hier?
  }

  .appointment-bar-heading {
    padding-top: 46px;
    padding-bottom: 46px;
    padding-right: 24px;

    @media(max-width: $screen-sm-max) {
      margin-bottom: 20px;
    }

    .appointment-bar-heading__title {
      margin-top: 0;
      margin-bottom: 6px;
    }
    .appointment-bar-heading__address {
      display: block;
      float: left;
    }

    &__badge {
      display: block;
      position: relative;
      width: 120px;
      color: $brand-white;
      padding: 4px 16px;
      background-color: $brand-red;
      margin-bottom: 20px;
      @include vendor(transition, $brand-transition);

      &:before {
        position: absolute;
        display: block;
        content: url(../images/badge-triangle.svg);
        left: 0;
        bottom: -18px;
      }

      > span {
        display: block;
        font-weight: $bold-font-weight;

        &.with-padding {
          padding: 12px 0;
        }
      }

      @media(min-width: $screen-md-min) {
        display: none;
      }
    }
  }
}

.appointment-flexbox {

  @media(min-width: $screen-md-min) {
    @include flexbox;
    @include flex-wrap(wrap);
    @include align-items(center);
  }

  .appointment-flexbox__content {

    @media(min-width: $screen-md-min) {
      @include justify-content(space-between);
    }
  }
  .appointment-flexbox__content--right {
    padding-top: 86px;
    margin-bottom: 46px;

    @media(min-width: $screen-md-min) {
      margin-left: auto;
      padding-top: 0;
      margin-bottom: 0;
    }
    @media(max-width: $screen-sm-max) {
      .btn {
        display: block;
        width: 100% !important;
      }
    }
  }
}

// Appointment Maps & Inputs
//
.appointment-maker {
  @extend .container;

  margin-bottom: 46px;

  .appointment-maker__inner {
    @include make-row;

    .appointment-maker__gmap {
      @include make-xs-column(12);
      height: 432px;
      background-color: $brand-white;
    }

    .appointment-maker__box {
      @media(min-width: $screen-md-min) {
        @include flexbox;
        @include align-items(center);
      }

      @include make-xs-column(12);
      // padding-right: 32px;
      height: auto;
      background-color: $brand-blue;

      .appointment-maker-headline {
        @include make-xs-column(12);
        @include make-md-column(1);

        @media(max-width: $screen-sm-max) {
          padding-top: 24px;
        }

        @media(min-width: $screen-md-min) {
          width: 11% !important; //override width
        }
      }

      .appointment-maker-form {
        @include make-xs-column(12);
        @include make-md-column(11);
        width: 100%;

        padding-top: 24px;
        padding-bottom: 24px;

        @media(min-width: $screen-md-min) {
          padding-top: 46px;
          padding-bottom: 46px;
        }
      }
    }

  }
}

.or-call-us {
  margin-top: 20px;
  position: relative;
  display: table;

  &:before {
    position: relative;
    content: url(../images/telephone-bar-icon.svg);
    display: table-cell;
    top: 6px;
    padding-right: 14px;
  }
}

// @media(min-width: $screen-md-min) {
//   .appointment-form-flexbox {
//     @include flexbox;
//     @include flex-direction(row);
//     @include flex-wrap(wrap);
//     @include align-items(center);
//     // @include justify-content(space-around);
//     @include justify-content(space-between);
//
//     .appointment-form-flexbox__content {
//       padding-left: 6px;
//       padding-right: 6px;
//       // @include flex(1 0 50%);
//     }
//   }
// }

@media(min-width: $screen-md-min) {
  #appointmentFullname,
  #appointmentEmail,
  #appointmentTelephone,
  #appointmentPrefdate,
  #appointmentSubmit {
    @include flex-basis(50%);
  }
  #appointmentQuestions {
    @include flex-basis(50%);
    margin-bottom: 0;
  }
  #appointmentCall {
    @include flex-basis(50%);
  }

  #appointmentFullname { @include order(1); }
  #appointmentEmail { @include order(2); }
  #appointmentTelephone { @include order(3); }
  #appointmentPrefdate { @include order(4); }
  #appointmentQuestions { @include order(5); }
  #appointmentSubmit { @include order(6); }
  #appointmentCall { @include order(7); }
}
// Textarea special styling
#appointmentQuestionsTextarea {
  padding: 8px 20px;
}

@media(min-width: $screen-lg-min) {
  #appointmentFullname,
  #appointmentEmail,
  #appointmentTelephone,
  #appointmentPrefdate,
  #appointmentSubmit {
    @include flex-basis(20%);
  }
  #appointmentQuestions {
    @include flex-basis(60%);
    margin-bottom: 0;
  }
  #appointmentCall {
    @include flex-basis(40%);
  }

  #appointmentQuestions { @include order(6); }
  #appointmentSubmit { @include order(5); }
}
// Textarea special styling
#appointmentQuestionsTextarea {
  padding: 8px 20px;
}
