/* ==========================================================================
   # HOMEPAGE RECENT ADDED
   ========================================================================== */

#home-swiper-recent-added {
  padding-bottom: 74px;

  &.swiper-container {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    z-index: 0;

    .swiper-wrapper {
      padding-bottom: 74px;

      .swiper-slide.swiper-slide-active {
        padding-left: 12px;
      }

      @media (max-width: $screen-sm-max) {

        .swiper-slide.swiper-slide-active,
        .swiper-slide.swiper-slide-next {
          padding-left: 12px;
          padding-right: 12px;
        }
      }
    }
    .swiper-pagination {
      z-index: -1;
      height: 16px;

      &.swiper-pagination-bullets {
        bottom: 86px;
      }
      > .swiper-pagination-bullet {

        @media (min-width: $screen-md-min) {
          margin: 0 6px;
          width: 16px;
          height: 16px;
        }

        &.swiper-pagination-bullet-active {
          background-color: $brand-red;
        }
      }
    }
  }
}
