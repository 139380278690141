@media print
{
    .single-cb_cpt_aanbod,
    .single-cb_cpt_projecten {
        .sharing {
            display: none;
        }
    }

    .appointment-flexbox__content {
        display: none;
    }

    .appointment-maker {
        display: none;
    }

    .news-contact-top-border {
        display: none;
    }

    .footer-flexbox {
        //.social-icons {
        //    display: none;
        //}
        //
        //.footer-flexbox__content:nth-child(2) {
        //    display: none;
        //}
        //
        //.footer-flexbox__content:nth-child(3) {
        //    display: none;
        //}
    }

    .realestate-feature-collapse {
        display: block !important;
    }
}
