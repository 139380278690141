/*!
 * Immo C&S, by juner.nl
 * Version:         1.2
 * Author:          Michel Hartevelt
 * Author: URI:     http://juner.nl/
 */

 // "bootstrap": "git://github.com/twbs/bootstrap.git#v4.0.0-alpha.6",

// SETTINGS
//
// Override project settings here
@import "settings/settings.immo-variables";

// GENERIC
//
// Build and include your Generic styles here.
@import "generic/generic.mixins";
@import "generic/generic.flexbox";
@import "generic/generic.helpers";
@import "generic/generic.ie-hacks.scss";

@import "components/components.skyforms";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/animatewithsass/animate.scss";
// endbower

// BASE
//
// Build and include your project’s Bases here.
@import "base/base.typography";
@import "base/base.forms";
@import "base/base.thematic-breaks";
@import "base/base.images";


// LAYOUTS
//
// Build and include your project’s Layouts here.
@import "layouts/layouts.header";
@import "layouts/layouts.page";
@import "layouts/layouts.search";

// NEWS PAGES
@import "layouts/layouts.news-archive";
@import "layouts/layouts.news-single";

// NIEUWBOUW & RENOVATIE
@import "layouts/layouts.nieuwbouw-renovatie";

// CONTACT
@import "layouts/layouts.contact";

@import "layouts/layouts.realestate-archive";
@import "layouts/layouts.footer";

// @import "layouts/layouts.testpage";
// @import "layouts/layouts.swiper";

// MODULES
//
// Build and include your project’s Modules here.
// @import "modules/modules.navbar";

// GENERAL MODULES
@import "modules/modules.pack";
@import "modules/modules.immo-filter";
@import "modules/modules.leader";
@import "modules/modules.login-register";
@import "modules/modules.bottom";
@import "modules/modules.lightbox-gallery"; //temporary
@import "modules/modules.actions";
@import "modules/modules.custom-select";

// HOMEPAGE MODULES
@import "modules/modules.home-slider";
@import "modules/modules.home-recent-added";

// ABOUTPAGE MODULES
@import "modules/modules.about-hero";
@import "modules/modules.about-profiles";

// SINGLE PAGE
@import "modules/modules.single-hero";
@import "modules/modules.single-content";
@import "modules/modules.single-gallery";

// FILTER MODULES
@import "modules/modules.sidebar-filter";
@import "modules/modules.appointments";

// FILTER MODULES
@import "modules/modules.error-header";

// SEARCH
@import "modules/modules.search-modal";
@import "modules/modules.search-popup";

// COMPONENTS
//
// Build and include your project’s UI Compoments here.
@import "../../bower_components/swiper/dist/css/swiper.css";
@import "../../bower_components/lightbox2/dist/css/lightbox.css";
// @import "components/components.swiper"; //maybe for later use?
@import "components/components.titles";
@import "components/components.buttons";
@import "components/components.blockquote";
@import "components/components.tables";
@import "components/components.forms";
@import "components/components.gravityforms";
@import "components/components.inputs";
@import "components/components.checkbox";
@import "components/components.radio";
@import "components/components.select";
@import "components/components.labels";
@import "components/components.facetwp";
@import "components/components.gallery-pagination";
@import "components/components.sharing";
@import "components/components.search-block";

// Icons
@import "components/components.line-icons"; //temp file
@import "components/components.font-awesome";
@import "components/components.immo-icons";

// REALESTATE OPTIONS
@import "components/components.realestate-card";
@import "components/components.realestate-options";
@import "components/components.nieuwbouw-card";

// REALESTATE SINGLE COMPONENTS
@import "components/components.realestate-single-gallery";
@import "components/components.contact-gallery";
@import "components/components.realestate-single-information";
@import "components/components.realestate-feature";
@import "components/components.steps";

// PRINT
@import "print/aanbod";
