/* ==========================================================================
   # Forms
   ========================================================================== */

// Base Styles
//
.form-group {
  .form-control {
    // padding: 14px 18px;
    padding: 10px 20px;
    border: 0;
    height: auto !important;
  }
  &.form-group--grey {
    .form-control {
      background-color: $brand-grey-base;
    }
    label {
      color: $brand-blue !important;
    }
  }
}

.gform_body .gform_fields .gfield .ginput_container input {
  padding: 10px 20px !important;
  background-color: orange;
}

// Base Auto Width Form-Groups
//
.form-group-auto-width {
  width: 100%;

  .btn,
  .dropdown-menu {
    width: 100%;
  }
}

// Base Labels
//
label {
  color: inherit;
  font-weight: $light-font-weight;
}

// Required Fields
//
.form-group--required {
  label:after {
    content: " * ";
    display: inline-block;
    position: relative;
    top: 0;
    right: 0;
    font-size: 110%;
    color: $brand-red;
  }
}

textarea {
  resize: none;
}
