/* ==========================================================================
   # Internet Explorer Hacks
   ========================================================================== */

 // IE 10 and above
 //
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  @media(min-width: $screen-md-min) {
    .appointmentFullname,
    .appointmentEmail,
    .appointmentTelephone,
    .appointmentPrefdate,
    .appointmentSubmit, {
      @include flex-basis(48% !important);
    }
    .appointmentQuestions {
      @include flex-basis(48% !important);
      margin-bottom: 0;
    }
    .appointmentCall {
      @include flex-basis(48% !important);
    }
  }

  @media(min-width: $screen-lg-min) {
    .appointmentFullname,
    .appointmentEmail,
    .appointmentTelephone,
    .appointmentPrefdate,
    .appointmentSubmit {
      @include flex-basis(18% !important);
    }
    .appointmentQuestions {
      @include flex-basis(58% !important);
    }
    .appointmentCall {
      @include flex-basis(39% !important);
    }
  }

}
