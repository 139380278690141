// Body text
//
p {
  margin: 0 0 ($line-height-computed / 2);
}

.lead {
  margin-bottom: $line-height-computed / 11;
  font-size: $font-size-large;
  font-weight: $light-font-weight;
  // line-height: 1.6;
  line-height: floor(($font-size-large * $line-height-large));
}

// Heading 1
h1,
.h1 {
  font-size: $font-size-h1;
  line-height: floor(($font-size-h1 * $line-height-base));
  font-weight: $bold-font-weight;
}
// Heading 2
h2,
.h2 {
  font-size: $font-size-h2;
  line-height: floor(($font-size-h2 * $line-height-base));
  font-weight: $bold-font-weight;
}
// Heading 3
h3,
.h3 {
  font-size: $font-size-h3;
  line-height: floor(($font-size-h3 * $line-height-base));
  font-weight: $bold-font-weight;
}
// Heading 4
h4,
.h4 {
  font-size: $font-size-h4;
  line-height: floor(($font-size-h4 * $line-height-base));
  font-weight: $bold-font-weight;
}
// Heading 4
h4,
.h4 {
  font-size: $font-size-h4;
  line-height: floor(($font-size-h4 * $line-height-base));
  font-weight: $bold-font-weight;
}
// Heading 5
h5,
.h5 {
  font-size: $font-size-h5;
  line-height: floor(($font-size-h5 * $line-height-base));
  font-weight: $bold-font-weight;
}
// Heading 6
h6,
.h6 {
  font-size: $font-size-h6;
  line-height: floor(($font-size-h6 * $line-height-base));
  font-weight: $bold-font-weight;
}


// Font Weight Light
//
.light {
  font-weight: $light-font-weight;
}
// Font Weight Bold
//
.bold {
  font-weight: $bold-font-weight;
}

// Font Size Small
small,
.small {
  font-size: floor((100% * $font-size-small / $font-size-base));
}
