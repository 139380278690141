/* ==========================================================================
   # Components Blockquote
   ========================================================================== */

blockquote {
  position: relative;
  display: table;
  margin: 24px 0;
  padding: 32px;
  width: 100%;
  font-size: $blockquote-font-size;
  font-style: italic;
  border: 1px solid $brand-seperator-default;
  background-color: $brand-grey-base;

  p,
  ul,
  ol {
    &:last-child {
      margin-bottom: 0;
    }
  }
  &:before {
    position: relative;
    display: table-cell;
    padding-right: 24px;
    content: url(../images/blockquote-icon.svg);
    vertical-align: middle;
  }
}
