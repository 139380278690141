.search-popup {
  display: none;
  padding: 3rem 3.5rem 3.5rem;

  .search-popup__title {
    font-size: 3.4rem;
    font-weight: normal;
    margin-bottom: 2rem;
    color: $brand-white;
  }

  .search-popup_bottom {
    display: table;
    margin-top: 2rem;
    width: 100%;

    .immo-radio {
      color: $brand-white;
    }

    .search-popup_bottom_col {
      display: table-cell;
      vertical-align: middle;

      &:last-child {
        width: 1px;
      }
    }
  }

  .parsley-errors-list {
    color: $brand-white;
    list-style: none;
    display: block;
    margin-top: .5rem;
    padding-left: 2rem;
  }

  @media (min-width: $screen-md-min) {
    padding: 7rem 7.4rem 7.5rem;
  }
}

.tingle-modal-box {
  background-color: $brand-red;
  border-radius: 0;

  .search-popup {
    display: block;
  }
}

.tingle-modal-box__content {
  padding: 0;
}
