/* ==========================================================================
   # Realestate Information
   ========================================================================== */

// Realestate Information
//
.realestate-information {
  @include make-row;

  margin-bottom: 44px;

  .realestate-information__fullwidth {
    @include make-sm-column(12);
  }

  .realestate-information__left {
    @include make-sm-column(6);
  }
  .realestate-information__right {
    @include make-sm-column(6);
  }

  .appointment-maker {
    width: 100%;
  }

  #realestate-map {
    height: 350px;
  }
}

.realestate-brochure-btn {
  padding-top: 34px;
  padding-bottom: 46px;
}
