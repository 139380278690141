/* ==========================================================================
   # Nieuwbouw Card
   ========================================================================== */

// Base Styles
//
.nieuwbouw-card {
  color: $brand-blue;
  text-decoration: none;
  @include vendor(transition, $brand-transition);
  display: block;
  padding: 2px 0;

  @media(min-width: $screen-md-min) {
    padding: 4px 2px 0;
  }

  &:hover {
    color: $brand-blue;
    text-decoration: none;

    .nieuwbouw-card__image {
      transform: scale(1.025);
    }
  }

  .nieuwbouw-card__inner {
    position: relative;
    overflow: hidden;

    .nieuwbouw-card__image {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 70%;
      background-size: cover;
      background-repeat: no-repeat;
      transition: transform .3s;

      .nieuwbouw-card__heading {
        position: absolute;
        bottom: 0;
        left: 15px;
        color: $brand-white;
        @include vendor(text-shadow, 0 2px 4px rgba($gray-base, .5));

        .title {
          margin-bottom: 6px;
        }
        .subtitle {
          margin-bottom: 24px;
        }
      }
    }

    .nieuwbouw-card__status {
      position: relative;
      top: 0;
      width: 100%;
      padding: 14px 0;
      text-align: center;
      font-size: 22px;
      z-index: 99;
      margin: 0;
    }

    .nieuwbouw-card__info {
      position: relative;
      z-index: 99;
      border-top: 0;
      padding: 9px 28px;
      text-align: left;
      background-color: $brand-red;
      text-underline: none !important;

      .title {
        margin-top: 0;
        margin-bottom: 0;
      }

      .subtitle {
        font-size: 18px;
        margin-top: 2px;
        margin-bottom: 5px;
      }

    }
  }
}
