/* ==========================================================================
   # Steps
   ========================================================================== */

// Responsive Images
//
.img-responsive {
 
}

// Rounded Images
//
.img-rounded {
  @include vendor(border-radius, 50%);
}
