/* ==========================================================================
   #MIXINS
   AUTHOR: Michel Hartevelt <michel@juner.nl>
   ========================================================================== */


/**
 * Headings 1 to 6 mixin
 *
 * Use as follows:
 *
 *  @include headings(1,3){ ... }
 *
 */
@mixin headings($from: 1, $to: 6){
  %base-heading {
    @content
  }

  @if $from >= 1 and $to <= 6{
    @for $i from $from through $to{
      h#{$i}{
        @extend %base-heading;
      }
    }
  }
}


/**
 * Vendor prefixing
 * Create vendor-prefixed CSS in one go, e.g.
 *
 * Use as follows:
 *
 *  @include vendor(border-radius, 4px);
 *
 */
@mixin vendor($property, $value...){
    -webkit-#{$property}:$value;
    -moz-#{$property}:$value;
    -ms-#{$property}:$value;
    -o-#{$property}:$value;
    #{$property}:$value;
}


/**
 * Keyframes mixin
 * Create CSS keyframe animations for all vendors in one go, e.g.
 *
 * Use as follows:
 *
 *  .foo{
 *    @include vendor(animation, shrink 3s);
 *  }
 *
 *  @include keyframe(shrink){
 *    from{
 *      font-size:5em;
 *    }
 *  }
 *
 */
 @mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

/**
 * Vertical alignment mixin
 *
 * Your markup must look like this:
 *
 *  <div class="container">
 *    <div class="box"></div>
 *  </div>
 *
 * You also need to specify the height and width of the container
 * If set you can use it like below:
 *
 *  .box {
 *    display: inline-block;
 *    width: 100px;
 *    height: 100px;
 *    @include vertical-align(50%);
 *  }
 *
 */
@mixin vertical-align($value) {
    position: relative;
    top: $value;
    -webkit-transform: translateY(#{ - $value});
    -ms-transform: translateY(#{ - $value});
    transform: translateY(#{ - $value});
}

/**
 * Box Shadow
 * Create vendor-prefixed CSS in one go, e.g.
 *
 * Use as follows:
 *
 *  @include box-shadow(10px, 10px, 2px, black, [true or false]);
 *
 */
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    @include vendor(box-shadow, inset $top $left $blur $color);
  } @else {
    @include vendor(box-shadow, $top $left $blur $color);
  }
}


@mixin centerer($horizontal: true, $vertical: true) {

  position: absolute;

  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);

  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

/**
 * Optional hyphenation
 *
 * Use as follows:
 *
 *  @mixin hyphens(hyphens, [none, manual, auto]);
 *
 */
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
  -moz-hyphens: $mode;
  -ms-hyphens: $mode; // IE10+
  -o-hyphens: $mode;
  hyphens: $mode;
}


// Justify content
//

/**
 * Justify content
 * Create vendor-prefixed CSS in one go, e.g.
 * Use as follows:
 *  @include vendor(border-radius, 4px);
 *
 */
// @mixin box-align($align, $justify){
//     -webkit-align-items: $align;
//     -webkit-justify-content: $justify;
//     -moz-align-items: $align;
//     -moz-justify-content: $justify;
//     -ms-align-items: $align;
//     -ms-justify-content: $justify;
//     align-items: $align;
//     justify-content: $justify;
// }
