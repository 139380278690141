/* ==========================================================================
   # Single Hero Module
   ========================================================================== */

// Single Hero Module Base Styles
//
.single-hero {
  @extend .container-fluid;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  background: $brand-grey-base;
  background-size: cover;
  background-position: center;
  border-bottom: 1px solid $brand-seperator-default;
  display: table;

  .single-hero__inner {
    @include make-row();
    display: table-cell;
    vertical-align: middle;

    .single-hero__contents {
      @extend .container;
      text-align: center;

      .single-hero-row {
        @include make-row();

        .single-hero-row__inner {
          @include make-xs-column(12);
          @include make-md-column(7);
          float: none !important;
          margin: 0 auto;
          padding-top: 112px;
          padding-bottom: 50px;

          .article {
            .article__date {
              color: $brand-grey-seperator;
              font-weight: $bold-font-weight;
            }
          }
        }
      }
      .single-hero-sharing-row {
        position: relative;
        z-index: 4;
      }
    }
  }
  // subtle gradient-overlay
  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    @include vendor(background, linear-gradient(to bottom, rgba($brand-white, .94) 0%,rgba($brand-grey-base, .94) 90%));
    background-blend-mode: multiply;
  }
}

// Default Page
// 
.single-default-hero {
  .single-hero__inner {
    .single-hero__contents {
      .single-hero-row {
        .single-hero-row__inner {
          padding-top: 112px;
          padding-bottom: 112px;

          article {
            .article__title {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
