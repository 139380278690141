/* ==========================================================================
   # Checkboxes
   ========================================================================== */

// Base Styles
//
.immo-checkbox {
  position: relative;
  display: block;
  padding-right: 10px;
  font-size: $font-size-base;
  font-weight: $light-font-weight;
  line-height: $line-height-base;
  color: $brand-blue;
  cursor: pointer;

  input {
    position: relative;
    margin-right: 10px;
    left: -9999px;

    &:checked + .immo-checkbox__icon:after {
      opacity: 1;
      @include vendor(transition, $brand-transition);
    }
  }

  .immo-checkbox__icon {
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    outline: none;
    border-color: $brand-white;
    border-width: 1px;
    border-style: solid;
    background: $brand-white;
    border-radius: 0;

    &:after {
      position: absolute;
      display: block;
      content: "\f00c";
      top: 1px;
      left: 2px;
      width: 14px;
      height: 14px;
      font-family: "FontAwesome";
      font-size: 12px;
      text-align: center;
      opacity: 0;
    }
  }
}

// Apply Same Style as the radio buttons
//
.immo-checkbox--as-radio {
  position: relative;
  display: block;
  padding-right: 10px;
  font-size: $font-size-base;
  font-weight: $light-font-weight;
  line-height: $line-height-base;
  color: $brand-blue;
  cursor: pointer;

  input {
    position: relative;
    margin-right: 10px;
    left: -9999px;

    &:checked + .immo-radio__icon:after {
      opacity: 1;
      @include vendor(transition, $brand-transition);
    }
  }

  .immo-radio__icon {
    position: absolute;
    top: 2px;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    outline: none;
    border-color: $brand-white;
    border-width: 1px;
    border-style: solid;
    background: $brand-white;
    border-radius: 0;

    &:after {
      position: absolute;
      display: block;
      content: "";
      top: 6px;
      left: 6px;
      width: 6px;
      height: 6px;
      // background-color: $brand-grey-seperator;
      background-color: $brand-blue;
      border-radius: 50%;
      opacity: 0;
    }
  }
}

// FacetWP Checkboxes
//
.facetwp-checkbox {
	position: relative;
	display: block;
	padding-left: 30px !important;
	background: none !important;
	background-size: 0 !important;
	// padding-right: 10px;

	&:before {
		content: "";
		position: absolute;
		display: block;
		top: 2px;
		left: 0;
		width: 18px;
		height: 18px;
		outline: none;
		border-color: $brand-white;
		border-width: 1px;
		border-style: solid;
		background: $brand-white;
		border-radius: 0;
		cursor: pointer;
	}

	&.checked {
		background: none !important;
		background-image: none !important;

    &:after {
      position: absolute;
      display: block;
      content: "\f00c";
      // top: 3px;
      top: 2px;
      left: 3px;
      width: 14px;
      height: 14px;
      font-family: "FontAwesome";
      font-size: 12px;
      text-align: center;
      opacity: 1;
    }
	}
}
