/* ==========================================================================
   # Steps
   ========================================================================== */

// Steps Base
//
.steps {
  @extend .container;
  position: relative;
  display: block;

  .steps__item {
    @include make-row;
    background: $brand-white;
    border-bottom: 2px solid $brand-light-seperator;
    padding-top: 46px;
    padding-bottom: 46px;

    @media (min-width: $screen-md-min) {
      padding-left: 46px;
      padding-right: 46px;
    }

    .steps-heading {
      @extend .pack;
      @extend .pack--middle;
      @include make-md-column(5);
      padding-bottom: 46px;

      @media (min-width: $screen-md-min) {
        padding-bottom: 0;
      }

      .steps-heading__title {
        @extend .pack__item;

        @media (min-width: $screen-md-min) {
          vertical-align: top;
        }
      }
      .steps-heading__image {
        @extend .pack__item;

        @media (min-width: $screen-md-min) {
          vertical-align: top;
          text-align: right;
        }

        img {
          display: inline-block;
        }
      }
    }
    .steps-text {
      @extend .pack;
      @extend .pack--top;
      @include make-md-column(6);

      .steps-text__paragraph {
        @extend .pack__item;
      }
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}

.steps__item--intro {
  border-bottom: 0 !important;
  padding-bottom: 0;

  .steps-heading {
    text-align: left;
    @media (min-width: $screen-lg-min) {
      margin-left: 288px;
    }
    @media (min-width: $screen-xlg-min) {
      margin-left: 366px;
    }
  }
}
