/* ==========================================================================
   # Sharing
   ========================================================================== */

// Sharing Base Styles
//
.sharing {
  display: table;

  .sharing__inner {
    display: table-cell;
    vertical-align: middle;
    padding: 24px 0;

    .sharing-title {
      display: inline-block;

      span {
        margin-top: -1px;
        margin-right: 4px;
      }
    }
    .social-icons {
      display: inline-block;
      .im:before {
        top: 7px; //pixel-perfect alignment
      }
    }
  }
  &.sharing--center {
    text-align: center;
    margin: 0 auto;
  }
  &.sharing--left {
    text-align: left;
  }
}
