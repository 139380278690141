/* ==========================================================================
#BUTTONS
========================================================================== */

//
// Base styles
//
.btn {
  box-shadow: none;
  border-radius: 0;

  &--bold {
    font-weight: $bold-font-weight;
  }
}

// Brand default white button
.btn--white {
  display: inline-block;
  cursor: pointer;
  padding: 10px 18px;
  background: $brand-white;
  border: 0;
  color: $brand-blue;
  white-space: nowrap;
  @include vendor(transition, $brand-transition);

  &:hover,
  &:focus {
    box-shadow: none;
    color: $brand-blue;
    outline: 0 !important;
    background-color: darken($brand-blue, 8%);
  }
}

// Brand default white button
.dropdown-menu {
  border-radius: 0;
  margin: 0px 0 0;
  border: 0;

  > li {
    color: $brand-blue;
    @include hyphens(auto);
    // word-wrap: break-word;
    word-break: break-all;

    a {
      color: $brand-blue;
      padding-top: 10px;
      padding-bottom: 10px;
      white-space: normal;

      &:hover {
        color: $brand-blue;
      }
    }
  }
  &.dropdown-menu--outlined {
    border: 1px solid $brand-blue;
    border-top: 0;
  }
}

// Brand Blue button
//
.btn--blue {
  @extend .btn--white;
  color: $brand-white;
  background: $brand-blue;

  &:hover,
  &:focus {
    color: $brand-white;
    background-color: darken($brand-blue, 8%);
  }
}
// Brand default Red button
//
.btn--red {
  @extend .btn--white;
  color: $brand-white;
  background: $brand-red;

  &:hover,
  &:focus {
    color: $brand-white;
    background-color: darken($brand-red, 8%);
  }
}

.btn--block {
  display: block;
  width: 100%;
  text-align: center;
}

.btn-outlined {
  background: transparent;
}

.btn-outlined--blue {
  background: transparent;
  border: 1px solid $brand-blue;
  outline: 0;

  &:focus {
    outline: 0;
  }
}

.btn-outlined--white {
  background: transparent;
  border: 1px solid $brand-white;
  outline: 0;
  color: $brand-white;

  &:hover {
    border: 1px solid $brand-blue;
  }

  &:focus {
    outline: 0;
    border: 1px solid $brand-blue;
  }
}

//
// Dropdown buttons
//
.dropdown-toggle {
  overflow-x: hidden;
}
.btn-group.open {
  button > i.fa-angle-down:before {
      // change the arrow when toggling
      content: "\f106" !important;
  }
  button {
    border-bottom: 0;
  }
}

// Button auto width
.btn-group-auto-width {
  width: 100%;

  .btn,
  .dropdown-menu {
    width: 100%;
  }
}

// Input Groups
//
.input-group-btn {
  .btn {
    padding: 8px 18px;
  }
}


.btn--close {
  position: absolute;
  right: 0;
  z-index: 1061;

  // .fa.fa-close {
  //   &:before {
  //     display: inline-block;
  //     content: url(../images/hamburger-icon-close.svg);
  //   }
  // }
}

.search-creator {
  display: none;

  @media (min-width: $screen-md-min) {
    display: block;
    float: right;
    margin-top: 15px;
  }

  .btn-search-creator {
    color: $brand-white;
    transition: $brand-transition;
    font-weight: $bold-font-weight;
    // border-bottom: 2px dotted $brand-white;


    &:after {
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 20px;
      bottom: -4px;
      left: 0;

      background-image: linear-gradient(to right, $brand-white 33%, rgba($brand-white, 0) 0%);
      background-position: 0 18px;
      background-size: 14px 2px;
      background-repeat: repeat-x;
    }


    &:hover {
      color: $brand-blue;
      text-decoration: none;
    }
  }
}
