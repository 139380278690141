/* ==========================================================================
   # Pack
   ========================================================================== */

.pack {
  width: 100%; /* [1] */
  // margin-left: 0;
  display: table;
  table-layout: fixed; /* [3] */
  // background: blue;
}

.pack__item {
  display: table-cell; /* [1] */
  // background-color: red;

  .pack--top > & {
    vertical-align: top;
  }

  .pack--middle > & {
    vertical-align: middle;
  }
  .pack--bottom > & {
    vertical-align: bottom;
  }
}

.pack--auto {
  table-layout: auto;
}

.pack--center {
  margin: 0 auto;
}
