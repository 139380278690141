//Reset links
a.reset-links {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}
// Overflow Hidden
.overflow-h {
  overflow: hidden;
}
// No Padding Left
.padding-left-h {
  padding-left: 0 !important;
}
// No Padding Right
.padding-right-h {
  padding-right: 0 !important;
}

.white {
  color: $brand-white;
}
.blue {
  color: $brand-blue;
}
.red {
  color: $brand-red;
}

// .content-sm {
// 	padding-top: 60px;
// 	padding-bottom: 60px;
// }

// Align Center
.align-center-xs {
  @media (max-width: $screen-xs-max) {
    text-align: center;
    margin: 0 auto;

    &.pull-left,
    &.pull-right {
      float: none !important;
    }
  }
}
.align-center-sm {
  @media (max-width: $screen-sm-max) {
    text-align: center;
    margin: 0 auto;

    &.pull-left,
    &.pull-right {
      float: none !important;
    }
  }
}
.align-center-md {
  @media (max-width: $screen-md-max) {
    text-align: center;
    margin: 0 auto;

    &.pull-left,
    &.pull-right {
      float: none !important;
    }
  }
}
.align-center-lg {
  @media (min-width: $screen-lg-min) {
    text-align: center;
    margin: 0 auto;

    &.pull-left,
    &.pull-right {
      float: none !important;
    }
  }
}

// Hidden class
//
.hidden {
  display: none;
}
