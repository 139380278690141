/* ==========================================================================
   # About Hero
   ========================================================================== */

// Base Syles
//
.about-hero {
  @extend .container;
  position: relative;
  display: block;

  .about-hero__inner {
    @include make-row;

    .about-hero__image {
      position: relative;
      display: block;
      background: $body-bg;
      background-position: center;
      background-size: cover;
      height: 600px;
      width: 100%;

      .about-hero__heading {
        // @include flexbox;
        // @include justify-content(center);
        // @include align-items(center);
        @include make-md-column(6);
        position: absolute;
        display: inline-block;
        bottom: 160px;
        padding-left: 46px;
        color: $brand-white;

        .about-hero__subtitle {
          font-size: $font-size-h4;
          font-weight: $bold-font-weight;
        }
        .about-hero__title {
          // font-size: $font-size-h1 * 1.2; ~/44
          font-size: floor($font-size-h1 * 1.58);
          line-height: 1;
          font-weight: $bold-font-weight;

          @media (max-width: $screen-sm-max) {
            font-size: floor($font-size-h1);
          }
        }
      }
    }
  }
}
