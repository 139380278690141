.custom-select {
  position: relative;

  select {
    background: white;
    border: 0;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  &::-ms-expand {
    display: none;
  }

  .fs-arrow {
    &:after {
      content: "\f107";
    }
  }
}
